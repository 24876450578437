/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCollaborationToolConfigDto } from '../models/CreateCollaborationToolConfigDto';
import type { GetCollaborationToolConfigResponseDto } from '../models/GetCollaborationToolConfigResponseDto';
import type { PatchCollaborationToolConfigDto } from '../models/PatchCollaborationToolConfigDto';
import type { SearchCollectionGetCollaborationToolConfigResponseDto } from '../models/SearchCollectionGetCollaborationToolConfigResponseDto';
import type { SearchCollectionGetCollaborationToolWebhookUrlDto } from '../models/SearchCollectionGetCollaborationToolWebhookUrlDto';
import type { SearchCollectionJiraIssueTypeDto } from '../models/SearchCollectionJiraIssueTypeDto';
import type { SearchCollectionJiraProjectDto } from '../models/SearchCollectionJiraProjectDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CollaborationToolService {

    /**
     * Get all Collaboration Tool configs
     * @returns SearchCollectionGetCollaborationToolConfigResponseDto Successfully retrieved Collaboration Tool configs
     * @throws ApiError
     */
    public static getAllCollaborationToolConfigs(): CancelablePromise<SearchCollectionGetCollaborationToolConfigResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/configs',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Patch Collaboration Tool config by type
     * @returns GetCollaborationToolConfigResponseDto Successfully updated Collaboration Tool config
     * @throws ApiError
     */
    public static patchCollaborationToolConfigByType({
        requestBody,
    }: {
        requestBody: PatchCollaborationToolConfigDto,
    }): CancelablePromise<GetCollaborationToolConfigResponseDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/ui/v1/collaboration-tools/configs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Connect your Collaboration Tool to Sifflet
     * @returns GetCollaborationToolConfigResponseDto Successfully connected Collaboration Tool
     * @throws ApiError
     */
    public static connectCollaborationTool({
        requestBody,
    }: {
        requestBody: CreateCollaborationToolConfigDto,
    }): CancelablePromise<GetCollaborationToolConfigResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/collaboration-tools/configs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete Collaboration Tool config by type
     * @returns void
     * @throws ApiError
     */
    public static deleteCollaborationToolConfigByType({
        type,
    }: {
        type: 'JIRA' | 'SERVICENOW',
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/collaboration-tools/configs/{type}',
            path: {
                'type': type,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all issue types for a give Jira project key
     * @returns SearchCollectionJiraIssueTypeDto Successfully retrieved Jira issue types
     * @throws ApiError
     */
    public static getIssueTypesForJiraProject({
        projectKey,
    }: {
        projectKey: string,
    }): CancelablePromise<SearchCollectionJiraIssueTypeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/jira/issue-types',
            query: {
                'projectKey': projectKey,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all Projects related to configured Jira instance
     * @returns SearchCollectionJiraProjectDto Successfully retrieved Jira projects
     * @throws ApiError
     */
    public static getAllJiraProjects(): CancelablePromise<SearchCollectionJiraProjectDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/jira/projects',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all Collaboration Tool webhook urls
     * @returns SearchCollectionGetCollaborationToolWebhookUrlDto Successfully retrieved Collaboration Tool webhooks urls
     * @throws ApiError
     */
    public static getAllCollaborationToolWebhookUrls(): CancelablePromise<SearchCollectionGetCollaborationToolWebhookUrlDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/webhook-urls',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
