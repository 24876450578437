<script lang="ts">
const MAX_ITEMS = 50
</script>
<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { BaseSearchFilterDto } from '@/api';
import i18n from '@/i18n';
import SearchFilterTreeviewSelector from './SearchFilterTreeviewSelector.vue';

interface SearchFilterTreeviewProps {
  filter?: BaseSearchFilterDto;
  tooltip?: string;
  hasSearch?: boolean;
  hideEmpty?: boolean;
  expanded?: boolean;
  value?: string[];
}

const props = defineProps({
  filter: null,
  tooltip: null,
  hasSearch: { type: Boolean },
  hideEmpty: { type: Boolean },
  expanded: { type: Boolean },
  value: null
});

type SearchFilterTreeviewEmit = {
  (event: 'input', items: string[]): void;
}
const emits = defineEmits(["input"]);



const search = _ref('');
let isLoading = _ref(true);
let isLazyLoading = _ref(true);

const items = _computed(() => {
  if (!props.filter?.children) return [];

  const itemsArray = props.filter?.children?.map((item) => {
    let name = '';
    if ('name' in item) name = item.name || i18n.t(`tree_selector.${item.id}`);
      return { ...item, name };
    }) || [];
    isLoading.value = false;
    return itemsArray;
});

const selectedItems = _computed(() => props.value || []);

const hasResults = _computed(() => !!items.value?.length);

const displayFilter = _computed(() => {
  if (!items.value?.length && props.hideEmpty) {
    return false;
  }
  return true;
});

const selectedItemCount = _computed(() => selectedItems.value?.length);
const type = _computed(() => props.filter?.type!);
const expandedState = _computed(() => (props.expanded ? 0 : 1));
const shouldLazyLoad = _computed(() => items.value?.length > MAX_ITEMS && !props.expanded);

const handleUpdateSelectedItems = (newSelected: string[]) => {
  emits('input', newSelected);
};

const handleUpdateLazyLoadingState = (newLoadingState: boolean) => {
  isLazyLoading.value = newLoadingState;
};
</script>

<template lang="pug">
.tree-box-selector( v-if="filter && displayFilter" )
  v-divider.my-2
  v-expansion-panels( class="mt-1" flat :value="expandedState")
    v-expansion-panel

      v-expansion-panel-header( expand-icon="icon-chevron-down-medium" )
        .d-flex.align-center
          b.body-2 {{ $t(`tree_selector.${type}`) }}
          v-tooltip( top v-if="tooltip" max-width="200" )
            template(v-slot:activator='{ on }')
              v-icon.ml-2( small v-on="on" ) icon-question-circle-outline
            span {{ tooltip }}
            div.tooltip-arrow-bottom
          div.d-flex.ml-auto
            v-avatar.mr-1(v-if="selectedItemCount" color="primary" size="16")
              span.avatar-text.white--text {{ selectedItemCount }}

      v-expansion-panel-content
        v-skeleton-loader.v-skeleton-loader-override.mr( v-if="isLoading"
          type="list-item-three-line"
          max-width="90%" )

        .d-flex.flex-column.align-center( v-else-if="!hasResults" )
          v-avatar.mt-6.mb-3(size="48" color="grey lighten-4" )
            v-icon( color="grey darken-2" ) icon-book-open
          b {{ $t('tree_selector.no-results', { type: $t(`tree_selector.${type}`) }) }}

        template(v-else)
          v-text-field.mb-2.mt-1.caption(
            v-if="hasSearch"
            v-model="search"
            height="24px"
            data-cy="tree-selector-search"
            outlined clearable hide-details )
            template(#prepend-inner)
              v-icon( color="grey" small) icon-search

          template( v-if="shouldLazyLoad" )
            v-skeleton-loader.v-skeleton-loader-override.mr(
              v-if="isLazyLoading"
              type="list-item-three-line"
              max-width="90%" )
            v-lazy
              SearchFilterTreeviewSelector(
                :type="type"
                :items="items"
                :selectedItems="selectedItems"
                @updateSelectedItems="handleUpdateSelectedItems"
                @updateLazyLoadingState="handleUpdateLazyLoadingState"
                :search="search"
                :lazyLoading="true")

          template(v-else)
            SearchFilterTreeviewSelector(
              :type="type"
              :items="items"
              :selectedItems="selectedItems"
              @updateSelectedItems="handleUpdateSelectedItems"
              :search="search"
              :lazyLoading="false")

</template>

<style lang="scss" scoped>

::v-deep {

  .v-treeview-node {
    &__toggle {
      scale: 0.75;
      transform: origin(50% 50%);

    }

    &__content {
      margin-top: 1px;
    }

    &__children .v-treeview-node--leaf {
      margin-right: 2px;

      .v-treeview-node__level {
        width: 21px;
      }
    }

    & .v-treeview-node__checkbox,
    &__content {
      margin-left: 0;
    }
  }

  .v-text-field .v-input__control .v-input__slot {
    min-height: auto;
    display: flex;
    align-items: center;
    padding: 0 8px !important;
  }

  .v-input__prepend-inner {
    margin-top: 4px;
  }

  .v-input__append-inner {
    margin-top: 2px;
  }

  .theme--light {

    .v-treeview-node__toggle.v-icon,
    .v-treeview-node__checkbox.v-icon {
      color: var(--v-textSecondary-base);
    }
  }
}
.v-avatar {
  border: 1px solid map-get($shades, 'white') !important;
}

.avatar-text {
  font-size: 8px;
}
</style>
