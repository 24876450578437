/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AlertingHookDto } from './AlertingHookDto';
import type { Criticality } from './Criticality';
import type { DatasetBriefDto } from './DatasetBriefDto';
import type { IncidentLightDto } from './IncidentLightDto';
import type { TagDto } from './TagDto';
import type { TimeZoneDto } from './TimeZoneDto';
import type { WorkspaceDto } from './WorkspaceDto';

export type RuleInfoDto = {
    canBeQualified: boolean;
    canBeScheduled: boolean;
    canManuallyRun: boolean;
    criticality: Criticality;
    datasets?: Array<DatasetBriefDto>;
    id: string;
    /**
     * @deprecated
     */
    lastRunStatus?: RuleInfoDto.lastRunStatus;
    lastRunTimestamp?: number;
    lastUnresolvedIncident?: IncidentLightDto;
    mails?: Array<AlertingHookDto>;
    msTeams?: Array<AlertingHookDto>;
    name: string;
    readOnly: boolean;
    ruleLabel: string;
    ruleStatus: RuleInfoDto.ruleStatus;
    ruleTemplateName: string;
    schedule?: string;
    scheduleTimezone?: TimeZoneDto;
    slackChannels?: Array<AlertingHookDto>;
    sourcePlatform: RuleInfoDto.sourcePlatform;
    supportAsCodeYAMLConversion: boolean;
    tags?: Array<TagDto>;
    unresolvedIncidents: number;
    workspace?: WorkspaceDto;
};

export namespace RuleInfoDto {

    export enum lastRunStatus {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        REQUIRES_YOUR_ATTENTION = 'REQUIRES_YOUR_ATTENTION',
        TECHNICAL_ERROR = 'TECHNICAL_ERROR',
        FAILED = 'FAILED',
    }

    export enum ruleStatus {
        NOT_EVALUATED = 'NOT_EVALUATED',
        PASSING = 'PASSING',
        NEEDS_ATTENTION = 'NEEDS_ATTENTION',
        FAILING = 'FAILING',
    }

    export enum sourcePlatform {
        SIFFLET = 'SIFFLET',
        DBT = 'DBT',
    }


}
