import { ref as _ref, shallowRef as _shallowRef, computed as _computed } from 'vue'
import {
 MonitorService, RuleCatalogAssetDtoV2, RuleStatusDto,
} from '@/api';
import { watch } from 'vue';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';
import { RuleTemplateName } from '@/modules/monitors/@types/monitor-types';

const auth = getModule(authModule, store);
const DATE_LOWER_LIMIT = 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds

let urn = _ref<string>('');
let monitors = _shallowRef<RuleCatalogAssetDtoV2[]>([]);
let loading = _ref<boolean>(false);

export type QualityItem = {
  total: number;
  failed: number;
  passed: number;
  needsAttention: number;
} | null

const volume: RuleTemplateName[] = ['SiffletCompletenessRule'];
const freshness: RuleTemplateName[] = ['SiffletMetadataFreshnessRule'];
const schemaChange: RuleTemplateName[] = ['SiffletSchemaChangeRule'];
const columnValidation: RuleTemplateName[] = [
  'SiffletDistributionRule',
  'SiffletCountDuplicateRule',
  'SiffletNotInListRule',
  'SiffletCountNullRowsRule',
  'SiffletUniqueRule',
  'SiffletReferentialIntegrityRule',
  'SiffletRangeRule',
  'SiffletIsEmailRule',
  'SiffletIsPhoneNumberRule',
  'SiffletIsUuidRule',
  'SiffletMatchesRegexRule',
];
const metrics: RuleTemplateName[] = [
  'SiffletNumericalTransformationRule',
  'SiffletCustomMetricsRule',
  'SiffletInterlinkedMetricsRule',
];
const others: RuleTemplateName[] = [
  'SiffletNoCodeRule',
  'SiffletSqlRule',
  'SiffletDuplicatesRule',
  'SiffletFreshnessRule',
];

const timeFilter = (ruleStatus: RuleCatalogAssetDtoV2['ruleStatus']) => ruleStatus && ruleStatus.latestRunDate && ruleStatus.latestRunDate > Date.now() - DATE_LOWER_LIMIT;

export const remapStatus = (ruleStatus?: RuleStatusDto[] | null): QualityItem => {
  if (!ruleStatus) return null;
  const filteredStatuses = ruleStatus
    .filter((status) => timeFilter(status));

  return {
    total: 1,
    failed: filteredStatuses.filter((status) => status.ruleStatus === RuleStatusDto.ruleStatus.FAILING).length ? 1 : 0,
    needsAttention: filteredStatuses.filter((status) => status.ruleStatus === RuleStatusDto.ruleStatus.NEEDS_ATTENTION).length ? 1 : 0,
    passed: filteredStatuses.filter((status) => status.ruleStatus === RuleStatusDto.ruleStatus.PASSING).length ? 1 : 0,
  };
};

const createQualityItem = (_monitors: RuleCatalogAssetDtoV2[], ruleTemplateNames: RuleTemplateName[]) => {
  const qualityMonitors = _monitors
    .filter((monitor) => timeFilter(monitor.ruleStatus))
    .filter((monitor) => ruleTemplateNames.includes(monitor.ruleTemplateName as RuleTemplateName))
    .filter((monitor) => monitor.ruleStatus?.ruleStatus !== RuleStatusDto.ruleStatus.NOT_EVALUATED);

  return qualityMonitors.length ? {
    total: qualityMonitors.length,
    failed: qualityMonitors.filter((monitor) => monitor.ruleStatus?.ruleStatus === RuleStatusDto.ruleStatus.FAILING).length,
    passed: qualityMonitors.filter((monitor) => monitor.ruleStatus?.ruleStatus === RuleStatusDto.ruleStatus.PASSING).length,
    needsAttention: qualityMonitors.filter((monitor) => monitor.ruleStatus?.ruleStatus === RuleStatusDto.ruleStatus.NEEDS_ATTENTION).length,
  } : null;
};

const volumeQuality = _computed<QualityItem>(() => createQualityItem(monitors.value, volume));
const freshnessQuality = _computed<QualityItem>(() => createQualityItem(monitors.value, freshness));
const schemaChangeQuality = _computed<QualityItem>(() => createQualityItem(monitors.value, schemaChange));
const columnValidationQuality = _computed<QualityItem>(() => createQualityItem(monitors.value, columnValidation));
const metricsQuality = _computed<QualityItem>(() => createQualityItem(monitors.value, metrics));
const othersQuality = _computed<QualityItem>(() => createQualityItem(monitors.value, others));
const detailRoute = _computed(() => ({
  name: 'data-catalog.asset.monitors',
  params: { urn: urn.value },
  query: {
    dataset: urn.value.split(':')[1],
  },
}));

const dataset = _computed(() => [urn.value.split(':')[1]]);
const domain = _computed(() => auth.selectedDomain?.name);
const requestBody = _computed(() => ({
  dataset: dataset.value,
  domain: domain.value,
  itemsPerPage: 1000,
  sort: ['lastRunDate,ASC'],
}));

const setUrn = (newUrn: string) => {
  urn.value = newUrn;
};

const getDataQuality = async () => {
  loading.value = true;
  monitors.value = (await MonitorService.getAllMonitor({ requestBody: requestBody.value })).searchRules.data ?? [];
  loading.value = false;
};

watch(() => urn.value, () => {
  if (urn.value) getDataQuality();
});

const useDataQuality = () => ({
    getDataQuality,
    monitors,
    loading,
    setUrn,
    volumeQuality,
    freshnessQuality,
    schemaChangeQuality,
    columnValidationQuality,
    metricsQuality,
    othersQuality,
    detailRoute,
    remapStatus,
  });

export default useDataQuality;
