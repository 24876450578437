<script lang="ts">
enum Edits {
  Schedule = 'schedule',
  Tags = 'tags',
  BusinessTerms = 'businessTerms',
  Notifications = 'notifications'
}
</script>
<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { computed } from 'vue';
import {
  AlertingHookDto, TagDto, RuleCatalogAssetDtoV2, RuleService, CancelablePromise, NotificationService,
} from '@/api';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import type { AvailableNotifications } from '@/@types/types';
import { Inputs } from '@/@types/types';
import type { RulePatchDto } from '@/api/models/RulePatchDto';
import { createPatchDto } from '@/api/patches';
import i18n from '@/i18n';



type MonitorsEditEmits = {
  (event: 'update'): void
}

const EDIT_PER_PLATFORM: Record<RuleCatalogAssetDtoV2.sourcePlatform, Edits[]> = {
  [RuleCatalogAssetDtoV2.sourcePlatform.DBT]: [Edits.Tags, Edits.BusinessTerms, Edits.Notifications],
  [RuleCatalogAssetDtoV2.sourcePlatform.SIFFLET]: [Edits.Schedule, Edits.Tags, Edits.BusinessTerms, Edits.Notifications],
};

const emit = defineEmits(["update"]);

const modalDialogRef = _ref<InstanceType<typeof ModalDialog> | null>(null);
const valid = _ref(false);
const counter = _ref(Inputs.INPUT_HIGH_CHARS);

let tags = _ref<TagDto[] | undefined>([]);
let terms = _ref<TagDto[]>([]);
let criticality = _ref<number | null>(null);
let removeExistingTags = _ref(false);
let removeExistingTerms = _ref(false);
const removeExistingSchedule = _ref(false);
let createIncidentOnFail = _ref<boolean | null>(null);
let removeExistingSlackChannels = _ref(false);
let removeExistingMsTeams = _ref(false);
let removeExistingWebhooks = _ref(false);
let removeExistingMails = _ref(false);
let removeExistingMessage = _ref(false);
let slackChannels = _ref<AlertingHookDto[]>([]);
let msTeams = _ref<AlertingHookDto[]>([]);
let webhooks = _ref<AlertingHookDto[]>([]);
let mails = _ref<AlertingHookDto[]>([]);
let hasMsTeams = _ref(true);
let hasWebhooks = _ref(true);
let hasSlack = _ref(true);
let hasMail = _ref(true);
let schedule = _ref('');
let message = _ref('');
let monitors = _ref<RuleCatalogAssetDtoV2[]>([]);
let sourcePlatforms = _ref<RuleCatalogAssetDtoV2.sourcePlatform[] | []>([]);

const showNotifications = _ref(false);
const messageRules = _ref([(v: string) => v?.length <= 1000 || '1000 chars max']);
const fields = _ref(['tags', 'terms', 'message', 'schedule', 'mails', 'slackChannels', 'msTeams', 'webhooks', 'createIncidentOnFail']);

const isSingleEdit = _computed(() => monitors.value.length === 1);
const monitorsCount = computed<number>(() => monitors.value.length);
const canNotify = _computed(() => hasSlack.value || hasMail.value || hasMsTeams.value || hasWebhooks.value);
const editingRules = _computed(() => sourcePlatforms.value.map((platform) => EDIT_PER_PLATFORM[platform]).flat());
const isEditableSchedule = _computed(() => editingRules.value.some((rule) => rule === Edits.Schedule));
const notificationIcon = _computed(() => (showNotifications.value ? 'icon-chevron-up' : 'icon-chevron-down'));

const hasNonEditableScheduleRule = _computed(() => {
  const newEditingRules = sourcePlatforms.value.map((platform) => EDIT_PER_PLATFORM[platform]);
  return newEditingRules.some((rules) => !rules.includes(Edits.Schedule));
});

const scheduleInputHint = computed<string>(() => (hasNonEditableScheduleRule.value
  ? i18n.t('monitors.dbt_schedule_cant_be_edited')
  : ''));

const notificationText = computed<string>(() => (showNotifications.value
  ? i18n.t('edit-dialog.hide_notifications')
  : i18n.t('edit-dialog.show_notifications')));

const getPatch = (currentState: RuleCatalogAssetDtoV2) => {
  let tagIds: any = [];
  let termIds: any = [];
  let mailIds: any = [];
  let slackIds: any = [];
  let msTeamsIds: any = [];
  let webhookIds: any = [];
  const newMessage = removeExistingMessage.value && !message.value ? '' : message.value || currentState.message;
  const newSchedule = removeExistingSchedule.value && !schedule.value ? '' : schedule.value || currentState.schedule;

  fields.value.forEach((field) => {
    switch (field) {
      case 'tags':
        tagIds = createPatchDto({ tags: tags.value }, currentState.tags, removeExistingTags.value, isSingleEdit.value);
        break;
      case 'terms':
        termIds = createPatchDto({ terms: terms.value }, currentState.terms, removeExistingTerms.value, isSingleEdit.value);
        break;
      case 'mails':
        mailIds = createPatchDto({ mails: mails.value }, currentState.mails, removeExistingMails.value, isSingleEdit.value);
        break;
      case 'slackChannels':
        slackIds = createPatchDto({ slackChannels: slackChannels.value }, currentState.slackChannels, removeExistingSlackChannels.value, isSingleEdit.value);
        break;
      case 'msTeams':
        msTeamsIds = createPatchDto({ msTeams: msTeams.value }, currentState.msTeams, removeExistingMsTeams.value, isSingleEdit.value);
        break;
      case 'webhooks':
        webhookIds = createPatchDto({ webhooks: webhooks.value }, currentState.webhooks, removeExistingWebhooks.value, isSingleEdit.value);
        break;
      default:
        break;
    }
  });

  return {
    tagIds,
    termIds,
    message: newMessage,
    schedule: newSchedule,
    createIncidentOnFail: createIncidentOnFail.value === null ? currentState.createIncidentOnFail : createIncidentOnFail.value,
    mailIds,
    slackChannelIds: slackIds,
    msTeamsIds,
    webhookIds,
    criticality: (criticality.value || criticality.value === 0) ? criticality.value : currentState.criticality,
  };
};

const init = () => {
  monitors.value = [];
  tags.value = [];
  terms.value = [];
  schedule.value = '';
  createIncidentOnFail.value = null;
  removeExistingTags.value = false;
  removeExistingTerms.value = false;
  slackChannels.value = [];
  removeExistingSlackChannels.value = false;
  msTeams.value = [];
  removeExistingMsTeams.value = false;
  webhooks.value = [];
  removeExistingWebhooks.value = false;
  mails.value = [];
  message.value = '';
  removeExistingMessage.value = false;
  removeExistingMails.value = false;
  hasMsTeams.value = false;
  hasWebhooks.value = false;
  hasSlack.value = false;
  hasMail.value = false;
  criticality.value = null;
};

const open = () => modalDialogRef.value!.openDialog();
const close = () => modalDialogRef.value!.closeDialog();

const update = async () => {
  const patches: RulePatchDto[] = [];
  let promises: CancelablePromise<any>[] = [];

  monitors.value.forEach((monitor) => patches.push(getPatch(monitor)));

  if (Array.isArray(monitors.value) && Array.isArray(patches)) {
    promises = monitors.value.map((monitor, index) => {
      const rulePatch = patches[index];
      return RuleService.patchRule({ id: monitor.id, requestBody: rulePatch });
    });
  } else {
    const requestBody = patches as RulePatchDto;
    monitors.value.map((monitor) => RuleService.patchRule({ id: monitor.id, requestBody }));
  }

  await Promise.all(promises);
  close();
  emit('update');
};

const setMonitors = async (newMonitors: RuleCatalogAssetDtoV2[]) => {
  init();
  monitors.value = newMonitors;

  const { isAvailableByType } = await NotificationService.getNotificationAvailability();
  const {
    SLACK, MAIL, MS_TEAMS, WEBHOOK,
  } = isAvailableByType as AvailableNotifications;
  hasSlack.value = SLACK;
  hasMail.value = MAIL;
  hasMsTeams.value = MS_TEAMS;
  hasWebhooks.value = WEBHOOK;
  sourcePlatforms.value = monitors.value.map(({ sourcePlatform }) => sourcePlatform);

  if (isSingleEdit.value) {
    const { id } = newMonitors[0];
    const monitor = await RuleService.getSiffletRuleById({ id });
    if (monitor.tags) tags.value = monitor.tags;
    if (monitor.terms) terms.value = monitor.terms;
    if (monitor.schedule) schedule.value = monitor.schedule;
    createIncidentOnFail.value = monitor.createIncidentOnFail;
    if (monitor.slackChannels) slackChannels.value = monitor.slackChannels;
    if (monitor.msTeams) msTeams.value = monitor.msTeams;
    if (monitor.webhooks) webhooks.value = monitor.webhooks;
    if (monitor.mails) mails.value = monitor.mails;
    if (monitor.message) message.value = monitor.message;
    if (monitor.criticality) criticality.value = monitor.criticality;
  }
  open();
};

const updateCriticality = (newCriticality: number) => {
  criticality.value = newCriticality;
};

defineExpose({ setMonitors });
</script>

<template lang="pug">
ModalDialog(
  ref="modalDialogRef"
  content-class="edit-dialog"
  :title="$tc('monitors.edit_dialog_title', monitorsCount)" )

  template( v-slot:body )
    v-form( v-model="valid" lazy-validation )
      v-row( no-gutters )
        v-col.mb-6( cols="12" )
          TagSelector(
            v-model="tags"
            data-cy="monitors-edit--tag-selector" )

          v-checkbox.mt-0(
            v-if="!isSingleEdit"
            v-model="removeExistingTags"
            :label="$t('edit-dialog.remove_existing_tags')"
            class="monitors-edit--remove-existing-tags"
            hide-details dense)

        v-col.mb-6( cols="12" )
          TermSelector(
            v-model="terms"
            data-cy="monitors-edit--term-selector" )

          v-checkbox.mt-0(
            v-if="!isSingleEdit"
            v-model="removeExistingTerms"
            :label="$t('edit-dialog.remove_existing_terms')"
            class="monitors-edit--remove-existing-terms"
            hide-details dense)

        v-col.mb-6( cols="12")
          CriticalitySelector(
            :value="criticality"
            @input="updateCriticality"
            hide-details
          )
          span(v-if="!isSingleEdit")
            v-icon.mr-2( small ) icon-warning-outline
            span {{ $t('edit-dialog.criticality_override') }}

        v-col.mb-6( v-if="isEditableSchedule" cols="12" )
          InputCron( v-model="schedule" data-cy="monitors-edit--schedule" :hint="scheduleInputHint" )

          v-checkbox.mt-0(
            v-if="!isSingleEdit"
            v-model="removeExistingSchedule"
            :label="$t('edit-dialog.remove_existing_schedule')"
            class="monitors-edit--remove-existing-schedule"
            hide-details dense)

        v-col( cols="12" )

          v-row( no-gutter )
            v-col( cols="12"
              v-if="canNotify"
              @click="showNotifications = !showNotifications"
              data-cy="monitors-edit--toggle-notifications"
              style="cursor: pointer;" )
              span.body-2.blue--text {{ notificationText }}
              v-icon.ml-2( small color="iconInfo"  ) {{ notificationIcon }}

          v-row(
            v-if="showNotifications"
            no-gutter
            data-cy="monitors-edit--notifications")

            v-col( cols="12" )
              v-checkbox.mt-0.pt-0(
                v-model="createIncidentOnFail"
                :indeterminate="createIncidentOnFail === null"
                :label="$t('edit-dialog.create_incident')"
                hide-details dense)

            v-col( v-if="hasWebhooks" cols="12" )
              WebhookTagSelector(
                v-model="webhooks"
                data-cy="monitors-edit--webhooks-selector")

              v-checkbox.mt-0.pt-0(
                v-if="!isSingleEdit"
                v-model="removeExistingWebhooks"
                :label="$t('edit-dialog.remove_existing_webhooks')"
                class="monitors-edit--remove-existing-webhooks"
                hide-details dense)

            v-col( v-if="hasSlack" cols="12" )
              SlackTagSelector(
                v-model="slackChannels"
                data-cy="monitors-edit--slack-selector")

              v-checkbox.mt-0.pt-0(
                v-if="!isSingleEdit"
                v-model="removeExistingSlackChannels"
                class="monitors-edit--remove-existing-slack-channels"
                :label="$t('edit-dialog.remove_existing_channels')"
                hide-details dense)

            v-col( v-if="hasMsTeams" cols="12" )
              MsTeamsTagSelector(
                v-model="msTeams"
                data-cy="monitors-edit--ms-teams-selector")

              v-checkbox.mt-0.pt-0(
                v-if="!isSingleEdit"
                v-model="removeExistingMsTeams"
                :label="$t('edit-dialog.remove_existing_channels')"
                class="monitors-edit--remove-existing-ms-teams-channels"
                hide-details dense)

            v-col( v-if="hasMail" cols="12" )
              MailTagSelector(
                v-model="mails"
                data-cy="monitors-edit--mail-selector")

              v-checkbox.mt-0.pt-0(
                v-if="!isSingleEdit"
                v-model="removeExistingMails"
                class="monitors-edit--remove-existing-mails"
                :label="$t('edit-dialog.remove_existing_mails')"
                hide-details dense)

            v-col( v-if="canNotify" cols="12" )
              v-textarea(
                v-model="message"
                :placeholder="$t('common.words.message_placeholder')"
                :counter="counter"
                :rules="messageRules"
                data-cy="monitors-edit--message"
                dense outlined
                persistent-placeholder)

              v-checkbox.mt-0.pt-0(
                v-if="!isSingleEdit"
                v-model="removeExistingMessage"
                class="monitors-edit--remove-existing-message"
                :label="$t('edit-dialog.remove_existing_message')"
                hide-details dense)

  template( v-slot:actions )
    SButton(
      color="primary"
      :text="$t('edit-dialog.save')"
      data-cy="monitors-edit--save"
      :async-action="update"
      :disabled="!valid")
</template>
