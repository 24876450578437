<script setup lang="ts">import { computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["input"]);
import { DomainDto, DomainUiLightDto, UserPermissionAssignmentDto } from '@/api';
import titleCase from '@/utils/filters/titleCase';

interface DomainRoleSelectorProps {
  disabled?: boolean;
  availableDomains: DomainDto[];
  checkboxCols?: number;
  inputCols?: number;
}
const props = defineProps({
  disabled: { type: Boolean },
  availableDomains: null,
  checkboxCols: { default: 6 },
  inputCols: { default: 6 },
  value: null
});

const { value: domains } = __MACROS_useVModel(["value", undefined, "input"]);

const roles = Object.values(DomainUiLightDto.role);

const sortedDomains = _computed(() => [...props.availableDomains].sort((domain) => (domain.name === 'All' ? -1 : 1))); // avoid mutating the props availableDomains

const isSelected = (selectedDomain: DomainDto) => domains.value.some((domain) => domain.id === selectedDomain.id);

const change = (checked: boolean, domain: DomainDto) => {
  if (checked) {
    domains.value = [...domains.value, {
      id: domain.id,
      name: domain.name,
      role: DomainUiLightDto.role.VIEWER,
      isAllDomain: domain.isAllDomain,
    }];
  } else {
    domains.value = domains.value.filter((d) => d.id !== domain.id);
  }
};
const getRole = (domain: DomainDto) => {
  const d = domains.value.find((_d) => _d.id === domain.id);
  if (d) {
    return d.role;
  }
  return '';
};

const changeRole = (role: DomainUiLightDto.role, domain: DomainDto) => {
  const d = domains.value.find((_d) => _d.id === domain.id);
  if (d) {
    d.role = role;
  }
};
</script>

<template lang="pug">
v-card( :disabled="disabled" data-cy="domain-role-selector" )
  v-row( v-for="domain in sortedDomains" no-gutters :key="domain.id" )
    v-col( :cols="checkboxCols" )
      v-checkbox.mb-0.mt-0.textPrimary--text(
        :input-value="isSelected(domain)"
        @change="change($event, domain)"
        :label="domain.name" dense)
        template( #label )
          span.textPrimary--text {{ domain.name }}
    v-col( :cols="inputCols" )
      v-select(
        v-if="isSelected(domain)"
        :value="getRole(domain)"
        :items="roles"
        @change="changeRole($event, domain)"
        :menu-props="{ bottom: true, offsetY: true }"
        data-cy="domain-role-selector-autocomplete"
        required dense outlined
        hide-details hide-no-data persistent-hint )

        template(v-slot:item="{ item }")
          div {{ titleCase(item) }}

        template( v-slot:selection="{ item }")
          div {{ titleCase(item) }}

</template>
