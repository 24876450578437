/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Transformation associated to the asset
 */
export type PublicTransformationRunDto = {
    /**
     * Last run date of the transformation
     */
    lastRunDate?: number;
    /**
     * Last run status of the transformation
     */
    lastRunStatus?: PublicTransformationRunDto.lastRunStatus;
    /**
     * Type of the transformation
     */
    type?: string;
};

export namespace PublicTransformationRunDto {

    /**
     * Last run status of the transformation
     */
    export enum lastRunStatus {
        SUCCESS = 'SUCCESS',
        ERROR = 'ERROR',
        SKIPPED = 'SKIPPED',
        PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
        NOT_TARGETED = 'NOT_TARGETED',
        UNKNOWN = 'UNKNOWN',
    }


}
