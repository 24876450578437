/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AssetCount = {
    count: number;
    integrationAssetCountType: AssetCount.integrationAssetCountType;
};

export namespace AssetCount {

    export enum integrationAssetCountType {
        TABLE = 'TABLE',
        SCHEMA = 'SCHEMA',
        DATABASE = 'DATABASE',
        PROJECT = 'PROJECT',
        FOLDER = 'FOLDER',
        DATASET = 'DATASET',
    }


}
