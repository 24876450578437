import { MAIN_ROUTES, SUB_ROUTES } from '@/router/components';
import domains from '@/router/domains';
import { BackgroundColors } from '@/@types/types';
import { UserUiDto } from '@/api';
import collaborationTools from '@/modules/collaboration-tools/routes/collaboration-tools';
import sso from '@/modules/settings/sso/routes/sso';
import ai from '@/modules/settings/ai/routes/ai';
import support from '@/modules/settings/support/routes/support';
import integration from '@/modules/settings/integration/routes/integration';
import settingsDrawerItems from '@/modules/settings/navigation/settings-drawer-items';

export default {
  path: '/settings',
  name: 'settings',
  redirect: { name: 'settings.tags' },
  meta: {
    requiresAuth: true,
    breadCrumb: 'app.navigation-drawer.settings',
    settingsMenu: true,
    navigation: settingsDrawerItems,
  },
  component: MAIN_ROUTES.SETTINGS,
  children: [
    {
      path: 'tags',
      name: 'settings.tags',
      meta: {
        requiresAuth: true,
        breadCrumb: 'app.navigation-drawer.tags',
        background: BackgroundColors.SECONDARY,

      },
      component: SUB_ROUTES.SETTINGS_TAGS,
    },
    domains,
    {
      path: 'users',
      name: 'settings.users',
      meta: {
        requiresAuth: true,
        roles: [UserUiDto.role.ADMIN],
        breadCrumb: 'app.navigation-drawer.users',
        background: BackgroundColors.SECONDARY,

      },
      component: SUB_ROUTES.SETTINGS_USERS,

    }, {
      path: 'access-tokens',
      name: 'settings.access-tokens',
      meta: {
        requiresAuth: true,
        roles: [UserUiDto.role.ADMIN],
        breadCrumb: 'app.navigation-drawer.access',
        background: BackgroundColors.SECONDARY,

      },
      component: SUB_ROUTES.SETTINGS_TOKENS,
    },
    {
      path: 'automatic-monitoring',
      name: 'settings.monitors.automatic-monitoring',
      meta: {
        requiresAuth: true,
        roles: [UserUiDto.role.ADMIN],
        breadCrumb: 'app.navigation-drawer.automatic-monitoring',
        background: BackgroundColors.SECONDARY,

      },
      component: SUB_ROUTES.MONITORING_TABLE_METADATA,
    },
    ai,
    support,
    integration,
    sso,
    collaborationTools,
  ],
};
