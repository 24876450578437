<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="rules"
    label='AWS Role ARN'
    data-cy="role-arn"
    dense clearable outlined required)

</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputRoleArn extends Vue {
  rules = [
    (v: string) => isConvertibleToNotBlankString(v) || 'AWS Role ARN is required',
    (v: string) => (v && /^(arn:(aws|aws-us-gov|aws-cn):iam::\d{12}:role\/[0-9A-Za-z\\+\\.@_,-]{1,64})/.test(v)) || 'Must be a AWS Role ARN ',
  ];
}
</script>
