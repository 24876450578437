/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RuleStatusDto = {
    latestRunDate?: number;
    ruleStatus: RuleStatusDto.ruleStatus;
};

export namespace RuleStatusDto {

    export enum ruleStatus {
        NOT_EVALUATED = 'NOT_EVALUATED',
        PASSING = 'PASSING',
        NEEDS_ATTENTION = 'NEEDS_ATTENTION',
        FAILING = 'FAILING',
    }


}
