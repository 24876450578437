<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["input"]);
import { watch } from 'vue';
import { VSelect } from 'vuetify/lib/components';
import { FieldSearchResultDto } from '@/api/models/FieldSearchResultDto';
import DatasetFieldChip from '@/modules/monitors/monitor/common/DatasetFieldChip.vue';

interface Props {
  items?: FieldSearchResultDto[],
  loading?: boolean,
  placeholder?: string,
  label?: string,
  allowAuto?: boolean,
  multiple?: boolean,
  rules?: (((input: any) => boolean | string) | boolean | string)[],
}
const props = defineProps({
  items: { default: () => [] },
  loading: { type: Boolean, default: false },
  placeholder: null,
  label: null,
  allowAuto: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
  rules: { default: () => [] },
  value: null
});

const { value: model } = __MACROS_useVModel(["value", undefined, "input"]);

const selectRef = _ref<(InstanceType<typeof VSelect> & { validate: () => boolean }) | null>(null);

const allItems = _computed(() => {
  let result: { name?: string, value?: string, divider?: boolean, icon?: string | null }[] = props.items
    .filter((t) => t.id) // VJSF automatically adds the current value into the list of possible items, which is not what we want at all
    .map((item) => ({ name: item.monitoringName, value: item.monitoringName, type: item.typeCategory }));

  if (props.allowAuto) result = [{ name: 'Auto-detected', value: 'auto' }, { divider: true }, ...result];
  return result;
});

const allRules = _computed(() => [...props.rules, (value: string | string[]) => {
  const allowedValues = allItems.value.map((item) => item.value);
  if (Array.isArray(value)) {
    return value.every((val) => allowedValues.includes(val));
  }
  return allowedValues.includes(value);
}]);

watch(() => allItems.value, () => {
  // Validation is not done by default on allItems update
  selectRef.value?.validate();
});
</script>

<template lang="pug">
v-autocomplete(ref="selectRef" :placeholder="placeholder" :rules="allRules" :label="label" :items="allItems" :loading="loading" :multiple="multiple" v-model="model" item-text="name" outlined dense menu-props="offset-y")
  template(#item="{ item, attrs, on }")
    .d-flex.align-center
      v-simple-checkbox(v-if="multiple" :value="attrs.inputValue" @click="on.click")
      DatasetFieldChip(:name="item.name" :type="item.type" color="textCodePrimary")
  template(#selection="{ item }")
    DatasetFieldChip.fieldselection(:name="item.name" :type="item.type" color="textCodePrimary")
</template>

<style lang="scss" scoped>
.fieldselection:not(:last-of-type) {
  ::after {
    content: ',\00a0';
  }
}
</style>
