<script lang="ts">
const GENERATE_METADATA_INTERVAL = 3000
</script>
<script lang="ts" setup>import { ref as _ref, toRef as _toRef, computed as _computed } from 'vue';
const asset = __MACROS_toRef(__props, "value");

import { toRef as __MACROS_toRef } from "vue";
defineProps({
  value: null
})
const __MACROS_emit = defineEmits(["input"]);
import { Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import router from '@/router';
import {
  AssetHeaderDto,
  type AssetPatchDto,
  AssetService,
  type AuthorizedAssetHeaderDto, CancelablePromise,
  type DagAssetOverview, type DagDetailsDto,
  type DashboardAssetOverview,
  type DatasetAssetOverview, type DatasetDetailsDto,
  type DeclaredAssetOverview, type FieldDto,
  type TransformationAssetOverview, type UnauthorizedAssetHeaderDto,
  MetadataJobsService,
  MetadataJobStatusDto,
  type CreateMetadataJobRequestDto,
} from '@/api';
import authModule from '@/store/modules/auth';
import store from '@/store';
import copyToClipboard from '@/utils/copyToClipboard';
import i18n from '@/i18n';
import { onMounted, onBeforeUnmount } from 'vue';
import eventBus, { EventType } from '@/utils/eventBus';
import EditDialog from '@/components/edit-dialog/Edit-Dialog.vue';
import SqlDialog from '@/components/sql-dialog/Sql-Dialog.vue';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import { useFeatures } from '@/plugins/feature-flag';
import useAsset from '@/modules/catalog/Asset';
import ProviderIcons from '@/utils/provider-icons';
import SIconComposer from '@/components/SIconComposer/SIconComposer.vue';



const { features } = useFeatures();
const auth = getModule(authModule, store);

const sqlDialogRef = _ref<SqlDialog | null>(null);
const editDialogRef = _ref<InstanceType<typeof EditDialog> | null>(null);
const modalDialogRef = _ref<ModalDialog | null>(null);

const __$temp_1 = (useAsset()),
  setShouldFetchDetails = _toRef(__$temp_1, 'setShouldFetchDetails');;



let headerInfo: AuthorizedAssetHeaderDto | UnauthorizedAssetHeaderDto | null = _ref(null);
let overviewData: DagAssetOverview | DashboardAssetOverview | DatasetAssetOverview | DeclaredAssetOverview | TransformationAssetOverview | null = _ref(null);
let isAuthorized: null | undefined | boolean = _ref(null);
let isMetadataGenerationEnabled = _ref<boolean>(false);
let lastCrocodileJob = _ref<MetadataJobStatusDto | null>(null);
let isMetadataJobRunning = _ref<boolean | null>(false);
let intervalReference: ReturnType<typeof setInterval>;

const isDataset = _computed(() => (__props.value.entityType === AssetHeaderDto.entityType.DATASET && headerInfo.value?.displayedType !== 'MODEL'));
const isDashboard = _computed(() => __props.value.entityType === AssetHeaderDto.entityType.DASHBOARD);
const isDeclaredAsset = _computed(() => __props.value.entityType === AssetHeaderDto.entityType.DECLARED_ASSET);
const canPreview = _computed(() => auth.userActions['metadata.asset.catalog-editor']);
const canCreateNewMonitor = _computed(() => auth.userActions['monitoring.monitor.write']);
const hasAsset = _computed(() => __props.value?.name);
const hasExternalLink = _computed(() => 'externalLink' in __props.value);
const externalLink = _computed(() => ('externalLink' in __props.value ? __props.value.externalLink : ''));
const hasQuality = _computed(() => __props.value.hasQuality);
const lineagePlatformCta = _computed(() => {
  const { lineagePlatformLabel } = __props.value;
  if (!lineagePlatformLabel) return '';
  return i18n.t('assets.view_in', { datasource: lineagePlatformLabel });
});
const image = _computed(() => ProviderIcons[__props.value.lineagePlatform]);

const bookmarkData = _computed(() => ({
  entityId: __props.value.urn,
  entityType: __props.value.entityType,
}));

const details = _computed(() => overviewData.value?.details);
const transformation = _computed(() => {
  const d: any = details.value as any;
  return d?.transformation ?? null;
});
const actionable = _computed(() => overviewData.value?.details?.actionable);
const hasTransformation = _computed(() => !!transformation.value);
const canEdit = _computed(() => auth.userActions['metadata.asset.catalog-editor']);

const hasPreview = _computed(() => {
  // By default, the preview feature is enabled, some clients wants to disable it
  const shouldHidePreview = features.isEnabled('metadata-asset-preview-disabled');
  return overviewData.value?.details?.hasPreview && !shouldHidePreview;
});

const getAssetOverview = async () => {
  headerInfo.value = await AssetService.getAssetHeaderByUrn({ urn: __props.value.urn });
  isAuthorized.value = headerInfo.value.accessAuthorized;
  if (!isAuthorized.value) return;
  overviewData.value = await AssetService.getAssetOverviewByUrn({ urn: __props.value.urn });
};

const editAsset = async () => {
  await getAssetOverview();
  if (details.value) {
    const {
      tags,
      terms,
      owners,
      description,
    } = details.value as DatasetDetailsDto | DagDetailsDto;
    editDialogRef.value?.setEntities([{
      urn: __props.value.urn, tags, terms, description, owners,
    }]);
  }
};

const createNewMonitor = () => {
  router.push({
    name: 'monitors.rule.new',
    query: {
      urn: __props.value.urn,
    },
  });
};

const copyAssetURIToClipboard = () => {
  copyToClipboard(__props.value.uri!, i18n.t('common.words.copy_to_clipboard_success_asset_uri', {
    value: __props.value.uri!,
  }));
};

const editDialogUpdateHandler = async ({ entities, patches }: { entities: string[], patches: { description?: string, tags?: string[], owners?: string[] } | { description?: string, tags?: string[], owners?: [] }[] }) => {
  let promises: CancelablePromise<FieldDto>[];
  if (Array.isArray(entities) && Array.isArray(patches)) {
    promises = entities.map((urn, index) => {
      const entityPatch = patches[index];
      return AssetService.patchAsset({ urn, requestBody: entityPatch });
    });
  } else {
    const requestBody = patches as AssetPatchDto;
    promises = entities.map((urn) => AssetService.patchAsset({ urn, requestBody }));
  }
  await Promise.all(promises);
  await getAssetOverview();
  setShouldFetchDetails.value(true);
};

const openPreview = () => modalDialogRef.value?.openDialog();
const closePreview = () => modalDialogRef.value?.closeDialog();

// METADATA GENERATION

const canGenerateMetadataSuggestions = _computed(() => auth.userActions['metadata.asset.catalog-editor']);

const metadataJobButtonSpanText = _computed(() => {
  if (!canGenerateMetadataSuggestions.value) return i18n.t('app.rights.suggestions_no_rights');
  if (isMetadataJobRunning.value) return i18n.t('assets.genarate_metadata_job_running_text');
  if (lastCrocodileJob.value) return i18n.t('assets.regenerate_metadata');
  return i18n.t('assets.generate_metadata');
});

const getLatestMetadataJobStatus = async () => {
  // Do not call the API if the user has no permission on this asset
  if (!canGenerateMetadataSuggestions.value) {
    lastCrocodileJob.value = null;
  } else {
    const crocodilePage = await MetadataJobsService.getMetadataJobsStatuses({ datasetUrn: __props.value.urn, metadataJobType: 'CROCODILE' });
    lastCrocodileJob.value = crocodilePage?.data?.length ? crocodilePage.data[0] : null;
    isMetadataJobRunning.value = lastCrocodileJob.value && [MetadataJobStatusDto.status.RUNNING, MetadataJobStatusDto.status.PENDING].includes(lastCrocodileJob.value!.status!);
  }
};

const getMetadataJobSettings = async () => {
  const metadataJobSettings = (await MetadataJobsService.getMetadataJobsSettings());
  isMetadataGenerationEnabled.value = metadataJobSettings.metadataGenerationEnabled!;
};

const stopPolling = () => {
  if (intervalReference) clearInterval(intervalReference);
};

const startPolling = () => {
  if (intervalReference) stopPolling();

  intervalReference = setInterval(async () => {
    await getMetadataJobSettings();
    if (isMetadataGenerationEnabled.value) {
      await getLatestMetadataJobStatus();
      if (!isMetadataJobRunning.value) {
        stopPolling();
        eventBus.$emit(EventType.REFRESH_METADATA);
      }
    } else {
      stopPolling();
    }
  }, GENERATE_METADATA_INTERVAL);
};

const sumbitMetadataJobRequest = async () => {
  const request: CreateMetadataJobRequestDto = { urn: __props.value.urn };
  try {
    isMetadataJobRunning.value = true;
    await MetadataJobsService.createMetadataJobs({ requestBody: request });
    startPolling();
    // TODO: We are temporarily muting the toast below. It will need tobe unmuted once the
    // toasts are displayed at the bottom of the page instead of at the top
    // Vue.notify({
    //   title: i18n.t('assets.genarate_metadata_job_running_title'),
    //   text: i18n.t('assets.genarate_metadata_job_running_text'),
    //   type: 'purple',
    // });
  } catch {
    isMetadataJobRunning.value = false;
    Vue.notify({
      title: i18n.t('assets.genarate_metadata_job_fail_title'),
      text: i18n.t('assets.genarate_metadata_job_fail_text'),
      type: 'error',
    });
  }
};

onMounted(async () => {
  await getAssetOverview();
  await getMetadataJobSettings();
  if (isMetadataGenerationEnabled.value) {
    await getLatestMetadataJobStatus();
    if (isMetadataJobRunning.value) startPolling();
  }
});

onBeforeUnmount(() => {
  stopPolling();
});
</script>

<template lang="pug">
.asset-overview-info-actions.ml-12
  .d-flex.flex-nowrap( v-if="hasAsset" )
    SButton.mr-2(
      v-if="hasQuality"
      color="primary"
      icon="icon-add"
      :text="$t('monitors.new_monitor')"
      @click="createNewMonitor"
      :disabled='!canCreateNewMonitor')

    SButton.mr-2(
      v-if="hasExternalLink && (isDashboard || isDeclaredAsset)"
      color="secondary"
      variant="outlined"
      :text="lineagePlatformCta"
      :tooltip="lineagePlatformCta"
      :to="externalLink" target="_blank" depressed)
      template(#icon)
        SIconComposer.mr-2(icon="icon-new-window" :image-src="image")

    SButton.mr-2(
      v-if="actionable && !isDeclaredAsset"
      icon="icon-edit"
      :text="$t('assets.edit')"
      @click="editAsset"
      :disabled="!canEdit"
      color="secondary"
      variant="outlined")
      template(#tooltip v-if="!canEdit")
        | {{ $t('assets.edit') }}

    SButton.mr-2(
      v-if="canPreview && hasPreview && actionable"
      :tooltip="$t('assets.preview_data')"
      icon="icon-eye"
      variant="outlined"
      color="secondary"
      @click="openPreview")

    SButton.mr-2(
      v-if="actionable && !isDeclaredAsset && hasTransformation"
      icon="icon-document-sql"
      :tooltip="$t('assets.view_sql')"
      @click="sqlDialogRef?.open()"
      color="secondary"
      variant="outlined")

    SButton.mr-2(
      v-if="hasExternalLink && !isDashboard && !isDeclaredAsset"
      color="secondary"
      variant="outlined"
      :tooltip="lineagePlatformCta"
      :to="externalLink" target="_blank" depressed)
      template(#icon)
        SIconComposer(icon="icon-new-window" :image-src="image")

    SButton.mr-2(
      v-if="isDataset && isMetadataGenerationEnabled"
      color="ai"
      :disabled="isMetadataJobRunning || !canGenerateMetadataSuggestions"
      @click="sumbitMetadataJobRequest"
      variant="outlined")
      template(#icon)
        v-progress-circular(v-if="isMetadataJobRunning" size="20" indeterminate width="2")
        SIcon(v-else icon="icon-sparkles" color="iconAi")
      template(#tooltip)
        span {{ metadataJobButtonSpanText }}
        v-chip.ml-2.beta-chip(
          v-if="!isMetadataJobRunning && canGenerateMetadataSuggestions"
          x-small outlined ) {{ $t('assets.beta') }}

    SThreeDotMenu(variant="text")
      Bookmark.mr-2( :bookmark="bookmarkData" is-menu)
      SMenuButton(
        v-if="asset.uri"
        @click="copyAssetURIToClipboard"
        icon="icon-copy"
        :text="$t('data-catalog.copy_uri')")

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    type="actions"
    width="500" )

  EditDialog(
    :fields="['tags', 'terms', 'description', 'owners']"
    ref="editDialogRef"
    @update="editDialogUpdateHandler"
    has-rich-text
  )

  ModalDialog(
    ref="modalDialogRef"
    :title="$t('common.words.preview')"
    :disableCancel="true" full )

    template( v-slot:body )
      AssetPreview(:urn="asset.urn")

    template( v-slot:actions )
      div.text-end
        SButton(
          color='primary'
          depressed
          :text="$t('common.words.close')"
          @click='closePreview')

  SqlDialog( v-if="hasTransformation" ref="sqlDialogRef" :sql-statement="transformation")
</template>

<style setup lang="scss">
.beta-chip {
  color: inherit !important;
}
</style>
