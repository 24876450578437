<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { watchEffect, watch } from 'vue';
import { BaseSearchFilterDto } from '@/api';
import { getProviderIconSrc } from '@/utils/provider-icons';
import { VTreeview } from 'vuetify/lib';
import CreationMethodIcon from './icons/CreationMethodIcon.vue';

interface SearchFilterTreeviewSelectorProps {
  type: string;
  items: { name: string; [key: string]: any }[];
  selectedItems: string[];
  lazyLoading: boolean,
  search: string;
}

const props =  defineProps({
  type: null,
  items: null,
  selectedItems: null,
  lazyLoading: { type: Boolean },
  search: null
});

type SearchFilterTreeviewSelectorEmit = {
  (event: 'updateSelectedItems', items: string[]): void;
  (event: 'updateLazyLoadingState', isLoading: boolean): void;
}
const emits = defineEmits(["updateSelectedItems", "updateLazyLoadingState"]);

const treeviewRef = _ref<typeof VTreeview | null>(null);

const type = _computed(() => props.type);
const items = _computed(() => props.items);
const lazyLoading = _computed(() => props.lazyLoading);
const selectedItems = _computed(() => props.selectedItems);
const search = _computed(() => props.search);

const isAssetType = _computed(() => type.value === BaseSearchFilterDto.type.ASSET_TYPE);
const isPlatform = _computed(() => type.value === BaseSearchFilterDto.type.PLATFORM);
const isTemplate = _computed(() => type.value === BaseSearchFilterDto.type.RULE_TEMPLATE_NAME);
const isHealth = _computed(() => type.value === BaseSearchFilterDto.type.HEALTH_STATUS);
const isUsage = _computed(() => type.value === BaseSearchFilterDto.type.USAGE);
const isTag = _computed(() => type.value === BaseSearchFilterDto.type.TAG);
const isTerm = _computed(() => type.value === BaseSearchFilterDto.type.TERM);
const isCriticality = _computed(() => type.value === BaseSearchFilterDto.type.CRITICALITY);
const isOwner = _computed(() => type.value === BaseSearchFilterDto.type.OWNER);
const isCreatedBy = _computed(() => type.value === BaseSearchFilterDto.type.CREATED_BY);
const isTechnology = _computed(() => type.value === BaseSearchFilterDto.type.TECHNOLOGY);
const isAssetIngestion = _computed(() => type.value === BaseSearchFilterDto.type.ASSET_INGESTION);
const isCreationMethod = _computed(() => type.value === BaseSearchFilterDto.type.CREATION_METHOD);
const isRuleStatus = _computed(() => type.value === BaseSearchFilterDto.type.RULE_STATUS);

// if item has even one children property with lenght > 0
const hasChildren = _computed(() => items.value?.some((item: any) => 'children' in item && item.children?.length));
const wrapperClass = _computed(() => (hasChildren.value ? 'v-treeview--has-children' : 'v-treeview--has-no-children'));

const handleSelectionChange = (newSelected: string[]) => {
  emits('updateSelectedItems', newSelected);
};

const loading = (item: any) => item.results === null;

watch(selectedItems.value, (newSelected) => {
  emits('updateSelectedItems', newSelected);
});

// Used to track when rendering of lazy loaded element ends
watchEffect(() => {
  if (treeviewRef.value && lazyLoading.value) {
    emits('updateLazyLoadingState', false);
  }
});
</script>

<template lang="pug">
div( :class="wrapperClass" )
  v-treeview(
    v-bind='$attrs'
    v-on='$listeners'
    @input="handleSelectionChange"
    v-model="selectedItems"
    :items="items"
    :search="search"
    selected-color="primary"
    selection-type="leaf"
    ref="treeviewRef"
    data-cy="tree-selector-treeview"
    transition
    selectable
    style="max-height: 200px; overflow-y: auto;")
    template( v-slot:label="{ item }" )
      .d-flex.align-center( :class="{ leaf: item.leaf }" )

        template( v-if="isAssetType" )
          AssetTypeIcon.ml-1( :value="item" )

        template( v-if="isPlatform" )
          PlatformIcon.ml-1( :value="item" )

        template( v-if="isTechnology" )
          img.mx-1( :src="getProviderIconSrc(item.icon)" width="20" :alt="item.name")
          span {{ item.name }}

        template( v-if="isAssetIngestion" )
          IngestionTypeIcon.mx-1( :ingestion-id="item.id")
          span.ml-1 {{ item.label }}

        template( v-if="isTemplate" )
          span.ml-1 {{ item.name }}

        template( v-if="isHealth" )
          QualityStatus.ml-1( :value="item.id" )

        template( v-if="isRuleStatus" )
          RunStatus( :status="item.id" ).ml-1

        template( v-if="isOwner || isCreatedBy" )
          AvatarList.ml-1( :value="[item]" size="20" name filter)

        template( v-if="isUsage")
          DataUsage.ml-1( :value="item.id" )

        template( v-if="isTag" )
          Tag.ml-1( :tag="item" small filter)

        template( v-if="isTerm" )
          Term.ml-1( :term="item" small )

        template( v-if="isCriticality" )
          Severity.ml-1.mr-2( :value="item.id" isFilter)

        template( v-if="isCreationMethod" )
          CreationMethodIcon.ml-1.mr-2( :value="item" )

        div.d-flex.ml-auto
          span.caption.grey--text( class="font-weight-medium" v-if="loading(item)")
            v-progress-circular.mr-2(indeterminate size="15")
          span.caption.grey--text(v-else) {{ item.results || '' }}
</template>

<style lang="scss" scoped>
.v-treeview--has-no-children {
  margin-left: -22px;
}
</style>
