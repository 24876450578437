import colors from '@/plugins/colors';
import * as d3 from 'd3';
import Lineage from './Lineage';
import Node from './Node';
import * as CONSTANTS from './constants';

const lineageCreatePlusIcons = (
  card: d3.Selection<SVGGElement, Node, SVGGElement, unknown>,
  instance: Lineage,
) => {
  const downStreamMinus = card
  .append('g')
  .attr('cursor', 'pointer')
  .attr('class', 'minus minus-downstreams')
  .attr('transform-origin', '0.5 0.5')
  .attr('highlightable', true)
  .attr('visibility', (d) => (d.canRetractDownstreamNodes(instance.getStartNode()) ? 'visible' : 'hidden'))
  .attr('transform', (d) => (d.data.attachedEntity
    ? `translate(${CONSTANTS.DEFAULT_FIELD_NODE_WIDTH - 10}, ${
      CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT / 2 + CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT - 10
    }) scale(0.5) `
    : `translate(${CONSTANTS.DEFAULT_FIELD_NODE_WIDTH - 10}, ${
      CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT / 2 - 10
    }) scale(0.5) `))
  .on('click', async (e: MouseEvent, d) => {
    const childrenDownstream = d.getNodesToRetractDownstream(d.location.x);

    instance.retractNode(childrenDownstream, d.id);
    d.retractDownstreamNode();
    instance.updateNodes();
    instance.updateLinks();
    e.stopPropagation();
  });

  downStreamMinus
    .append('circle')
    .attr('class', 'circle_minus circle_minus_downstreams')
    .attr('cx', 18)
    .attr('cy', 18)
    .attr('r', 18)
    .attr('stroke', colors.grey.lighten2)
    .attr('stroke-width', '1')
    .attr('fill', colors.shades.white)
    .attr('fill-opacity', 0)
    .transition()
    .duration(CONSTANTS.ANIMATION_DURATION)
    .ease(d3.easeLinear)
    .attr('fill-opacity', 1);

  downStreamMinus
    .append('path')
    .attr('d', 'M8.93849407,18.6895785 L26.8154822,18.6895785')
    .attr('class', 'minus_line minus_horizontal_line_downstreams')
    .attr('stroke', colors.blue.darken4)
    .attr('stroke-width', 2)
    .attr('stroke-linecap', 'square');

  const downStreamPlus = card
    .append('g')
    .attr('cursor', 'pointer')
    .attr('class', 'plus plus-downstreams')
    .attr('transform-origin', '0.5 0.5')
    .attr('highlightable', true)
    .attr('visibility', (d) => (d.allDownstreamNodesWereDeployed() ? 'hidden' : 'visible'))
    .attr('transform', (d) => (d.data.attachedEntity
        ? `translate(${CONSTANTS.DEFAULT_FIELD_NODE_WIDTH - 10}, ${
            CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT / 2 + CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT - 10
          }) scale(0.5) `
        : `translate(${CONSTANTS.DEFAULT_FIELD_NODE_WIDTH - 10}, ${
            CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT / 2 - 10
          }) scale(0.5) `))

    .on('click', async (e: MouseEvent, d) => {
      e.stopPropagation();
      const target = e.target as SVGAElement;
      target.style.opacity = '0.5';
      const isLoading = target.getAttribute('is-loading');
      if (!isLoading) {
        const deepLevel = instance.spreadAll ? 'all' : 'one';
        await instance.getDownstreams(d.data, deepLevel);
        target.removeAttribute('is-loading');
        target.style.opacity = '1';
        instance.update(false);
      }
    });

  downStreamPlus
    .append('circle')
    .attr('class', 'circle_plus circle_plus_downstreams')
    .attr('cx', 18)
    .attr('cy', 18)
    .attr('r', 18)
    .attr('stroke', colors.grey.lighten2)
    .attr('stroke-width', '1')
    .attr('fill', colors.shades.white)
    .attr('fill-opacity', 0)
    .transition()
    .duration(CONSTANTS.ANIMATION_DURATION)
    .ease(d3.easeLinear)
    .attr('fill-opacity', 1);

  downStreamPlus
    .append('path')
    .attr('d', 'M8.93849407,18.6895785 L26.8154822,18.6895785')
    .attr('class', 'plus_line plus_horizontal_line_downstreams')
    .attr('stroke', colors.blue.darken4)
    .attr('stroke-width', 2)
    .attr('stroke-linecap', 'square');

  downStreamPlus
    .append('path')
    .attr('d', 'M17.8769881,9.33313406 L17.8769881,27.2101222')
    .attr('class', 'plus_line plus_vertical_line_downstreams')
    .attr('stroke', colors.blue.darken4)
    .attr('stroke-width', 2)
    .attr('stroke-linecap', 'square');

  const upStreamMinus = card
  .append('g')
  .attr('class', 'minus minus-upstreams')
  .attr('transform-origin', '0.5 0.5')
  .attr('highlightable', true)
  .attr('visibility', (d) => (d.canRetractUpstreamNodes(instance.getStartNode()) ? 'visible' : 'hidden'))
  .attr('transform', (d) => (d.data.attachedEntity
    ? `translate(-10, ${
      CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT + CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT / 2 - 30
    }) scale(0.5)`
    : `translate(-10, ${CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT / 2 - 10}) scale(0.5) `))
  .on('click', async (e: MouseEvent, d) => {
    const childrenUpstream = d.getNodesToRetractUpstream(d.location.x);

    instance.retractNode(childrenUpstream, d.id);
    d.retractUpstreamNode();
    instance.updateNodes();
    instance.updateLinks();
    e.stopPropagation();
  });

  upStreamMinus
  .append('circle')
  .attr('class', 'circle_minus circle_minus_upstreams')
  .attr('cx', 18)
  .attr('cy', 18)
  .attr('r', 18)
  .attr('stroke', colors.grey.lighten2)
  .attr('stroke-width', '1')
  .attr('fill', colors.shades.white)
  .attr('fill-opacity', 0)
  .transition()
  .duration(CONSTANTS.ANIMATION_DURATION)
  .ease(d3.easeLinear)
  .attr('fill-opacity', 1);

  upStreamMinus
  .append('path')
  .attr('d', 'M8.93849407,18.6895785 L26.8154822,18.6895785')
  .attr('class', 'minus_line minus_horizontal_line_upstreams')
  .attr('stroke', colors.blue.darken4)
  .attr('stroke-width', 2)
  .attr('stroke-linecap', 'square');

  const upStreamPlus = card
    .append('g')
    .attr('class', 'plus plus-upstreams')
    .attr('transform-origin', '0.5 0.5')
    .attr('highlightable', true)
    .attr('visibility', (d) => (d.allUpstreamNodesWereDeployed() ? 'hidden' : 'visible'))
    .attr('transform', (d) => (d.data.attachedEntity
        ? `translate(-10, ${
            CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT + CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT / 2 - 30
          }) scale(0.5)`
        : `translate(-10, ${CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT / 2 - 10}) scale(0.5) `))
    .on('click', async (e: MouseEvent, d) => {
      e.stopPropagation();
      const target = e.target as SVGAElement;
      target.style.opacity = '0.5';
      const isLoading = target.getAttribute('is-loading');
      if (!isLoading) {
        target.setAttribute('is-loading', 'true');
        const deepLevel = instance.spreadAll ? 'all' : 'one';
        await instance.getUpstreams(d.data, deepLevel);
        target.removeAttribute('is-loading');
        target.style.opacity = '1';
        instance.update(false);
      }
    });

  upStreamPlus
    .append('circle')
    .attr('class', 'circle_plus circle_plus_upstreams')
    .attr('cx', 18)
    .attr('cy', 18)
    .attr('r', 18)
    .attr('stroke', colors.grey.lighten2)
    .attr('stroke-width', '1')
    .attr('fill', colors.shades.white)
    .attr('fill-opacity', 0)
    .transition()
    .duration(CONSTANTS.ANIMATION_DURATION)
    .ease(d3.easeLinear)
    .attr('fill-opacity', 1);

  upStreamPlus
    .append('path')
    .attr('d', 'M8.93849407,18.6895785 L26.8154822,18.6895785')
    .attr('class', 'plus_line plus_horizontal_line_upstreams')
    .attr('stroke', colors.blue.darken4)
    .attr('stroke-width', 2)
    .attr('stroke-linecap', 'square');

  upStreamPlus
    .append('path')
    .attr('d', 'M17.8769881,9.33313406 L17.8769881,27.2101222')
    .attr('class', 'plus_line plus_vertical_line_upstreams')
    .attr('stroke', colors.blue.darken4)
    .attr('stroke-width', 2)
    .attr('stroke-linecap', 'square');
};

export default lineageCreatePlusIcons;
