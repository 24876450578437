import { StaticFreshnessThresholdSettings, ThresholdModeEnum, type MonitorSettingsOptions } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';
import { dynamicSettings } from '@/modules/monitors/monitor-wizard/threshold-settings/thresholdSettings';

const SiffletFreshnessRule: MonitorSettingsOptions = {
  thresholdSettings: {
    modes: [
      {
        ...dynamicSettings,
        hasBoundChoice: false,
      },
      {
        type: ThresholdModeEnum.STATIC_FRESHNESS,
        convertThresholdToLegacy: () => ({
          comparisonType: 'static',
        }),
        getDefault: (): StaticFreshnessThresholdSettings => ({
          thresholdMode: ThresholdModeEnum.STATIC_FRESHNESS,
          valueMode: null,
        }),
      },
    ],
    defaultMode: ThresholdModeEnum.STATIC_FRESHNESS,
    values: { min: 0, max: 1, isInteger: true },
  },
  hasGroupBy: true,
  hasWhere: true,
  hasPartitioning: true,
  hasTimeSettings: true,
};

export default SiffletFreshnessRule;
