/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetCollaborationToolItemDto = {
    id: string;
    itemKey: string;
    itemUrl?: string;
    type: GetCollaborationToolItemDto.type;
};

export namespace GetCollaborationToolItemDto {

    export enum type {
        JIRA = 'JIRA',
        SERVICENOW = 'SERVICENOW',
    }


}
