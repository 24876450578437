import { type DrawerItems } from '@/components/navigation-drawer/Navigation-Drawer-Items';

const settingsDrawerItems: DrawerItems = {
  items: [
    {
      title: 'settings.navigation.platform',
      icon: 'icon-options',
      actions: ['setting.tag.read', 'setting.alert.read', 'setting.preferences.write'],
      disabled: true,
      items: [
        {
          title: 'app.navigation-drawer.tags',
          actions: ['setting.tag.read'],
          to: {
            name: 'settings.tags',
          },
          dataCy: 'settings.tags',
        },
        {
          title: 'app.navigation-drawer.ai',
          actions: ['setting.preferences.write'],
          to: {
            name: 'settings.ai',
          },
          dataCy: 'settings.ai',
        },
        {
          title: 'app.navigation-drawer.tools',
          actions: ['setting.alert.read'],
          to: {
            name: 'settings.tools',
          },
          dataCy: 'settings.alert',
        },
      ],
    },
    {
      title: 'settings.navigation.monitor',
      icon: 'icon icon-monitor-square',
      actions: ['setting.monitors.automatic-monitoring.write'],
      disabled: true,
      items: [
        {
          title: 'app.navigation-drawer.automatic-monitoring',
          actions: ['setting.monitors.automatic-monitoring.write'],
          to: {
            name: 'settings.monitors.automatic-monitoring',
          },
          dataCy: 'settings.monitors.automatic-monitoring',
        },
      ],
    },
    {
      title: 'settings.navigation.integration',
      icon: 'icon icon-plug-connection',
      actions: ['setting.preferences.write'],
      disabled: true,
      items: [
        {
          title: 'app.navigation-drawer.integration-preference',
          actions: ['setting.preferences.write'],
          to: {
            name: 'settings.integration',
          },
          dataCy: 'settings.integration',
        },
      ],
    },
    {
      title: 'settings.navigation.permissions',
      icon: 'icon-user-admin',
      actions: ['setting.user.read', 'setting.domain.read', 'setting.token.read', 'setting.sso.read'],
      disabled: true,
      items: [
                {
          title: 'app.navigation-drawer.users',
          actions: ['setting.user.read'],
          to: {
            name: 'settings.users',
          },
          dataCy: 'settings.users',
        },
        {
          title: 'app.navigation-drawer.domains',
          actions: ['setting.domain.read'],
          to: {
            name: 'domains',
          },
          dataCy: 'settings.domains',
        },
        {
          title: 'app.navigation-drawer.access',
          actions: ['setting.token.read'],
          to: {
            name: 'settings.access-tokens',
          },
          dataCy: 'settings.access',
        },
        {
          title: 'app.navigation-drawer.sso',
          actions: ['setting.sso.read'],
          to: {
            name: 'settings.sso',
          },
          dataCy: 'settings.sso',
        },
      ],
    },
    {
      title: 'settings.navigation.support',
      icon: 'icon-chat-question',
      actions: ['setting.preferences.write'],
      disabled: true,
      items: [
        {
          title: 'app.navigation-drawer.support',
          actions: ['setting.preferences.write'],
          to: {
            name: 'settings.support',
          },
          dataCy: 'settings.support',
        },
      ],
    },
  ],
};

export default settingsDrawerItems;
