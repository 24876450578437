import { render, staticRenderFns } from "./Asset-Overview-Info-Actions.vue?vue&type=template&id=f383f622&lang=pug"
import script from "./Asset-Overview-Info-Actions.vue?vue&type=script&lang=ts&setup=true"
export * from "./Asset-Overview-Info-Actions.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./Asset-Overview-Info-Actions.vue?vue&type=style&index=0&id=f383f622&prod&setup=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports