/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GroupIdentifierDto } from './GroupIdentifierDto';
import type { ParameterizedQueryDto } from './ParameterizedQueryDto';

export type RuleRunDetailsByGroupDto = {
    canBeQualified: boolean;
    canShowFailingRows: boolean;
    debugSql?: ParameterizedQueryDto;
    dimension: string;
    group: GroupIdentifierDto;
    groupStatus: RuleRunDetailsByGroupDto.groupStatus;
    hasGraph: boolean;
    result?: string;
    /**
     * @deprecated
     */
    status?: RuleRunDetailsByGroupDto.status;
};

export namespace RuleRunDetailsByGroupDto {

    export enum groupStatus {
        NOT_EVALUATED = 'NOT_EVALUATED',
        PASSING = 'PASSING',
        NEEDS_ATTENTION = 'NEEDS_ATTENTION',
        FAILING = 'FAILING',
    }

    export enum status {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        REQUIRES_YOUR_ATTENTION = 'REQUIRES_YOUR_ATTENTION',
        TECHNICAL_ERROR = 'TECHNICAL_ERROR',
        FAILED = 'FAILED',
    }


}
