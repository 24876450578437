<template lang="pug">
v-autocomplete.tag-selector(
  v-bind='$attrs'
  v-on='$listeners'
  :loading="loading"
  :items="mails"
  :search-input.sync="searchString"
  :hint="hint"
  :append-icon="appendIcon"
  @change="searchString=''"
  @click:append="clear"
  placeholder="Start typing to search"
  item-value="id"
  :item-text="item => item.externalHook"
  data-cy="mail-tag-selector"
  cache-items dense outlined
  hide-no-data persistent-hint
  return-object multiple )

  template(v-if="canRemove" v-slot:prepend-item)
    v-list-item( ripple @mousedown.prevent @click='toggleNull' )
      v-list-item-action
        v-icon {{ icon }}
      v-list-item-content
        v-list-item-title {{ $t('selectors.remove_existing') }}
    v-divider.mt-2

  template(v-slot:label)
    .d-flex
      v-icon( class="mr-1" v-if="isNull" color="iconWarning" small ) icon-warning-fill
      span( :class="labelClass" ) {{ label }}

  template(v-slot:item="{ item, on, attrs }")
    v-list-item( :class="itemClass(attrs)" ripple @mousedown.prevent v-on="on" active data-cy="mail-selector-item" )
      v-list-item-action
        v-icon {{ icon(attrs) }}
      v-list-item-content
        v-list-item-title
          .d-flex.flex-column.ml-1
            .body-2.grey--text.text--darken-4 {{ item.externalHook }}
            .caption.grey--text.text--grey-secondary(v-if="item.user") {{ userName(item) }}

</template>

<script lang="ts">
// eslint-disable-next-line
// @ts-nocheck
import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  AlertingHookDto, AlertingHookService, UserUiDto, UserService,
} from '@/api';
import authModule from '@/store/modules/auth';
import store from '@/store';

const auth = getModule(authModule, store);

@Component
export default class MailTagSelector extends Vue {
  @Prop({ default: 'Select one or multiple configured email addresses' })
  readonly hint!: string;

  searchString = '';

  mails: AlertingHookDto[] = [];

  users: UserUiDto[] = [];

  loading = false;

  get canRemove(): boolean {
    return 'can-remove' in this.$attrs;
  }

  get searchOption(): any {
    return this.searchString === null ? '' : this.searchString;
  }

  get isNull(): boolean {
    return this.$attrs.value === null;
  }

  get label(): string {
    return this.isNull ? this.$t('selectors.emails_remove_existing') : this.$t('selectors.emails_label');
  }

  get labelClass(): string {
    return this.isNull ? 'warning--text' : '';
  }

  get appendIcon(): string {
    return this.isNull || this.$attrs.value?.length ? 'icon-dismiss' : '';
  }

  icon(attrs: any): string {
    return attrs.inputValue ? 'icon-check-square-fill' : 'icon-check-square-outline-empty';
  }

  itemClass(attrs: any): string {
    return attrs.inputValue ? 'primary--text v-list-item--active' : '';
  }

  color(attrs: any): string {
    return attrs.inputValue ? 'primary' : '';
  }

  userName(hook: AlertingHookDto) {
    const user = this.users.find((u) => u.email === hook.externalHook);
    return auth.getUser && auth.getUser.id === user?.id ? this.$t('users.currentUserDisplay', { user: user.name }) : user?.name;
  }

  @Watch('searchString')
  searchHandler() {
    this.fetchAllMailAlertingHooks();
  }

  clear() {
    this.$emit('input', undefined);
  }

  putCurrentUserFirst() {
    const currentUserEmail = auth.getUser?.email;
    const currentUserIndex = this.mails.findIndex((mail) => mail.externalHook === currentUserEmail);
    if (currentUserIndex !== -1) {
      const hookIndex = this.mails.splice(currentUserIndex, 1)[0];
      this.mails.unshift(hookIndex);
    }
  }

  async fetchAllMailAlertingHooks() {
    this.loading = true;
    const { data } = await AlertingHookService.getAllMailAlertingHooks();
    this.mails = data!;
    this.putCurrentUserFirst();
    this.loading = false;
  }

  async fetchAllUsers() {
    const { data } = await UserService.getAllUsers();
    this.users = data!;
  }

  toggleNull() {
    !this.isNull ? this.$emit('input', null) : this.$emit('input', []);
  }

  mounted() {
    this.fetchAllMailAlertingHooks();
    this.fetchAllUsers();
  }
}
</script>
