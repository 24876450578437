<template lang="pug">
v-row( v-if="hasStatus" data-cy="monitors-summary")
  v-col( cols="12" )
    v-card( class="pa-4 border-around" )
      v-container( fluid )
        v-row( no-gutters )
          v-col.mb-2( cols="auto"  )
            .d-flex.align-center.text-h5
              v-icon.mr-2 icon-monitor-square
              .font-weight-medium {{ $t('monitors.page_name') }}

          v-col.mb-2( cols="auto" class="ml-auto" )
            .d-flex.align-center.justify-end.flex-wrap-reverse
              v-btn(
                data-cy="monitors-summary--add-monitor"
                :disabled="!canAddMonitor"
                @click='addMonitor'
                color="primary"
                class="custom-primary" )
                v-icon( left ) icon-add
                | {{ $t('monitors.new_monitor') }}

        v-row( no-gutters )
          v-col
            b.text-h6 {{ $t('monitors.last_run_summary') }}

        v-row( no-gutters )
          v-col
            .ms.mt-2
              v-tooltip(
              v-for="segment in segments"
              :key="segment.status"
              top
              nudge-top="5"
              content-class="extended-tooltip tooltip-arrow-bottom with-events")
                template( v-slot:activator='{ on }')
                  .ms--block(
                    :data-cy="`monitors-summary--segment-${segment.status}`"
                    v-on="on"
                    :style="{ width: segment.width, backgroundColor: segment.backgroundColor }" )
                    b( :class="segment.textColor" ) {{ segment.label }}
                RunStatus( :status="segment.status" )

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import authModule from '@/store/modules/auth';
import monitorsModule from '@/store/modules/monitors';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import { createColor } from '@/utils/filters/health';
import { RuleInfoDto } from '@/api';

let monitors: monitorsModule;
let auth: authModule;

@Component
export default class MonitorsSummary extends Vue {
  get hasStatus() {
    return this.grandTotal;
  }

  get errorCount() {
    return monitors.countByRuleStatus[RuleInfoDto.ruleStatus.FAILING] || 0;
  }

  get warningCount() {
    return monitors.countByRuleStatus[RuleInfoDto.ruleStatus.NEEDS_ATTENTION] || 0;
  }

  get successCount() {
    return monitors.countByRuleStatus[RuleInfoDto.ruleStatus.PASSING] || 0;
  }

  get inProgressCount() {
    return monitors.countByRuleStatus[RuleInfoDto.ruleStatus.NOT_EVALUATED] || 0;
  }

  get canAddMonitor() {
    return auth.userActions['monitoring.monitor.write'];
  }

  get grandTotal() {
    return this.errorCount + this.warningCount + this.successCount + this.inProgressCount;
  }

  get segments() {
    return [{
      show: this.errorCount > 0,
      label: this.errorCount,
      textColor: 'white--text',
      status: RuleInfoDto.ruleStatus.FAILING,
      width: `${(this.errorCount / this.grandTotal) * 100}%`,
      backgroundColor: createColor(RuleInfoDto.ruleStatus.FAILING, true),
    },
    {
      show: this.warningCount > 0,
      label: this.warningCount,
      textColor: 'white--text',
      status: RuleInfoDto.ruleStatus.NEEDS_ATTENTION,
      width: `${(this.warningCount / this.grandTotal) * 100}%`,
      backgroundColor: createColor(RuleInfoDto.ruleStatus.NEEDS_ATTENTION, true),
    }, {
      show: this.successCount > 0,
      label: this.successCount,
      textColor: 'white--text',
      status: RuleInfoDto.ruleStatus.PASSING,
      width: `${(this.successCount / this.grandTotal) * 100}%`,
      backgroundColor: createColor(RuleInfoDto.ruleStatus.PASSING, true),
    }, {
      show: this.inProgressCount > 0,
      label: this.inProgressCount,
      textColor: 'white--text',
      status: RuleInfoDto.ruleStatus.NOT_EVALUATED,
      width: `${(this.inProgressCount / this.grandTotal) * 100}%`,
      backgroundColor: createColor(RuleInfoDto.ruleStatus.NOT_EVALUATED, true),
    }].filter((segment) => segment.show);
  }

  addMonitor() {
    this.$router.push({
      path: '/monitors/rule/add',
    });
  }

  beforeCreate() {
    monitors = getModule(monitorsModule, store);
    auth = getModule(authModule, store);
  }

  async mounted() {
    monitors.getSummary();
  }
}
</script>

<style lang="scss" scoped>
.ms {
  height: 32px;
  display: flex;
  flex-wrap: wrap;

  &--block {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(map-deep-get($grey, "base"), 1);
    transition: width 0.5s linear;

    &:first-child {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }

    &:last-child {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}
</style>
