/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateJiraIssueDto } from './CreateJiraIssueDto';

export type CreateCollaborationToolItemDto = {
    item: CreateJiraIssueDto;
    type: CreateCollaborationToolItemDto.type;
};

export namespace CreateCollaborationToolItemDto {

    export enum type {
        JIRA = 'JIRA',
        SERVICENOW = 'SERVICENOW',
    }


}
