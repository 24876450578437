/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConnectorDto } from './ConnectorDto';
import type { DbtModelDto } from './DbtModelDto';
import type { DescriptionDto } from './DescriptionDto';
import type { SearchCollectionFieldLevelSearchFieldDto } from './SearchCollectionFieldLevelSearchFieldDto';
import type { TagDto } from './TagDto';
import type { UserDto } from './UserDto';

export type FieldLevelSearchDto = {
    canDoAutoCoverage?: boolean;
    connectorDto?: ConnectorDto;
    datasourceId?: string;
    datasourceName: string;
    dbtModelDto?: DbtModelDto;
    declaredSource?: boolean;
    description?: string;
    displayedType: FieldLevelSearchDto.displayedType;
    entityType: FieldLevelSearchDto.entityType;
    externalDescriptions?: Array<DescriptionDto>;
    fields?: SearchCollectionFieldLevelSearchFieldDto;
    health?: FieldLevelSearchDto.health;
    id: string;
    label: string;
    lastModifiedDate?: string;
    lineagePlatform: FieldLevelSearchDto.lineagePlatform;
    metadataRefresh?: number;
    name: string;
    owners?: Array<UserDto>;
    searchWeight?: number;
    tags?: Array<TagDto>;
    terms?: Array<TagDto>;
    uri?: string;
    urn?: string;
    usage?: FieldLevelSearchDto.usage;
};

export namespace FieldLevelSearchDto {

    export enum displayedType {
        SCHEMA = 'SCHEMA',
        DATABASE = 'DATABASE',
        TABLE = 'TABLE',
        DYNAMIC_TABLE = 'DYNAMIC_TABLE',
        EXTERNAL_TABLE = 'EXTERNAL_TABLE',
        VIEW = 'VIEW',
        MATERIALIZED_VIEW = 'MATERIALIZED_VIEW',
        SNOWFLAKE_STREAM = 'SNOWFLAKE_STREAM',
        DASHBOARD = 'DASHBOARD',
        REPORT = 'REPORT',
        MODEL = 'MODEL',
        DAG = 'DAG',
        ORCHESTRATOR = 'ORCHESTRATOR',
        FIELD = 'FIELD',
        MONITOR = 'MONITOR',
        NONE = 'NONE',
        TRANSFORMATION = 'TRANSFORMATION',
        CONNECTOR = 'CONNECTOR',
        ML_MODEL = 'ML_MODEL',
        DECLARED_ASSET = 'DECLARED_ASSET',
        PIPELINE = 'PIPELINE',
    }

    export enum entityType {
        INTEGRATION = 'INTEGRATION',
        DATASOURCE = 'DATASOURCE',
        DATASOURCE_INGESTION_RUN = 'DATASOURCE_INGESTION_RUN',
        DATASET = 'DATASET',
        DASHBOARD = 'DASHBOARD',
        CHART = 'CHART',
        COLLECTION = 'COLLECTION',
        DATASET_FIELD = 'DATASET_FIELD',
        DAG = 'DAG',
        TRANSFORMATION = 'TRANSFORMATION',
        RULE_RUN = 'RULE_RUN',
        INCIDENT = 'INCIDENT',
        USER = 'USER',
        ACCESS_TOKEN = 'ACCESS_TOKEN',
        SIFFLET_RULE = 'SIFFLET_RULE',
        CONFIG = 'CONFIG',
        TAG = 'TAG',
        DOMAIN = 'DOMAIN',
        ALERTING_HOOK = 'ALERTING_HOOK',
        RULE_MONITORING_RECOMMENDATION = 'RULE_MONITORING_RECOMMENDATION',
        DATAPOINT_QUALIFICATION = 'DATAPOINT_QUALIFICATION',
        DECLARED_ASSET = 'DECLARED_ASSET',
        WEBHOOK = 'WEBHOOK',
        SIFFLET_AGENT = 'SIFFLET_AGENT',
        SIFFLET_AGENT_JOB = 'SIFFLET_AGENT_JOB',
        AI_METADATA_PREDICTION = 'AI_METADATA_PREDICTION',
    }

    export enum health {
        CRITICAL = 'CRITICAL',
        AT_RISK = 'AT_RISK',
        HEALTHY = 'HEALTHY',
        UNMONITORED = 'UNMONITORED',
        NOT_SUPPORTED = 'NOT_SUPPORTED',
    }

    export enum lineagePlatform {
        ATHENA = 'ATHENA',
        BIGQUERY = 'BIGQUERY',
        FIREBOLT = 'FIREBOLT',
        PRESTO = 'PRESTO',
        REDSHIFT = 'REDSHIFT',
        SNOWFLAKE = 'SNOWFLAKE',
        DATABRICKS = 'DATABRICKS',
        MSSQL = 'MSSQL',
        MYSQL = 'MYSQL',
        SYNAPSE = 'SYNAPSE',
        POSTGRES = 'POSTGRES',
        H2 = 'H2',
        ORACLE = 'ORACLE',
        LIGHTDASH = 'LIGHTDASH',
        LOOKER = 'LOOKER',
        METABASE = 'METABASE',
        MICROSTRATEGY = 'MICROSTRATEGY',
        TABLEAU = 'TABLEAU',
        POWER_BI = 'POWER_BI',
        QLIK = 'QLIK',
        QUICKSIGHT = 'QUICKSIGHT',
        STITCH = 'STITCH',
        AIRBYTE = 'AIRBYTE',
        AIRFLOW = 'AIRFLOW',
        COMPOSER = 'COMPOSER',
        DAGSTER = 'DAGSTER',
        DBT = 'DBT',
        DBTCLOUD = 'DBTCLOUD',
        FIVETRAN = 'FIVETRAN',
        MWAA = 'MWAA',
        PREFECT = 'PREFECT',
        TALEND = 'TALEND',
        HUBSPOT = 'HUBSPOT',
        INFORMATICA = 'INFORMATICA',
        MULESOFT = 'MULESOFT',
        SALESFORCE = 'SALESFORCE',
        SEGMENT = 'SEGMENT',
        SLACK = 'SLACK',
        CENSUS = 'CENSUS',
        HIGH_TOUCH = 'HIGH_TOUCH',
        AMAZON_SAGEMAKER = 'AMAZON_SAGEMAKER',
        DATAIKU = 'DATAIKU',
        MICROSOFT_AZURE_ML = 'MICROSOFT_AZURE_ML',
        GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
        SIGMA = 'SIGMA',
        UNKNOWN = 'UNKNOWN',
        AZURE = 'AZURE',
        GOOGLE_DRIVE = 'GOOGLE_DRIVE',
        GOOGLE_SHEETS = 'GOOGLE_SHEETS',
        GOOGLE_CLOUD_STORAGE = 'GOOGLE_CLOUD_STORAGE',
        S3 = 'S3',
        EXCEL = 'EXCEL',
        GITHUB = 'GITHUB',
        SIXSENSE = 'SIXSENSE',
        FIFTEENFIVE = 'FIFTEENFIVE',
        THREESIXTYLEARNING = 'THREESIXTYLEARNING',
        ACCELDATA = 'ACCELDATA',
        ACRYL_DATA = 'ACRYL_DATA',
        ACTIAN_VECTOR = 'ACTIAN_VECTOR',
        ACTIVE_CAMPAIGN = 'ACTIVE_CAMPAIGN',
        ADA = 'ADA',
        ADJUST = 'ADJUST',
        ADOBE = 'ADOBE',
        ADP_WFN = 'ADP_WFN',
        ADROLL = 'ADROLL',
        AFFINITY = 'AFFINITY',
        AIRCALL = 'AIRCALL',
        AIRTABLE = 'AIRTABLE',
        ALATION = 'ALATION',
        ALCHEMER = 'ALCHEMER',
        ALVIN_AI = 'ALVIN_AI',
        AMAZON = 'AMAZON',
        AMAZON_CLOUDFRONT = 'AMAZON_CLOUDFRONT',
        AMAZON_DOCUMENTDB = 'AMAZON_DOCUMENTDB',
        AMAZON_DYNAMODB = 'AMAZON_DYNAMODB',
        AMAZON_FIREHOSE = 'AMAZON_FIREHOSE',
        AMAZON_SELLING_PARTNER = 'AMAZON_SELLING_PARTNER',
        AMPLITUDE = 'AMPLITUDE',
        ANAPLAN = 'ANAPLAN',
        ANOMALO = 'ANOMALO',
        APACHE_KAFKA = 'APACHE_KAFKA',
        APACHE_SPARK = 'APACHE_SPARK',
        APPCUES = 'APPCUES',
        APPFIGURES = 'APPFIGURES',
        APPLE = 'APPLE',
        APPLE_APP_STORE = 'APPLE_APP_STORE',
        APPSFLYER = 'APPSFLYER',
        ASANA = 'ASANA',
        ASSEMBLED = 'ASSEMBLED',
        ATLAN = 'ATLAN',
        ATLASSIAN_CONFLUENCE = 'ATLASSIAN_CONFLUENCE',
        ATLASSIAN_JIRA = 'ATLASSIAN_JIRA',
        ATLASSIAN_OPSGENIE = 'ATLASSIAN_OPSGENIE',
        AUTH0 = 'AUTH0',
        AUTODESK_BIM_360 = 'AUTODESK_BIM_360',
        AWIN = 'AWIN',
        AWS_CLOUDTRAIL = 'AWS_CLOUDTRAIL',
        AWS_COST_EXPLORER = 'AWS_COST_EXPLORER',
        AWS_INVENTORY = 'AWS_INVENTORY',
        AWS_LAMBDA = 'AWS_LAMBDA',
        BALLOT_READY = 'BALLOT_READY',
        BAMBOOHR = 'BAMBOOHR',
        BIGCOMMERCE = 'BIGCOMMERCE',
        BIGEYE = 'BIGEYE',
        BILLINGPLATFORM = 'BILLINGPLATFORM',
        BIRDEYE = 'BIRDEYE',
        BLACKBOARD = 'BLACKBOARD',
        BOX = 'BOX',
        BRAINTREE = 'BRAINTREE',
        BRAZE = 'BRAZE',
        BREX = 'BREX',
        BUILDKITE = 'BUILDKITE',
        BUZZSPROUT = 'BUZZSPROUT',
        CALENDLY = 'CALENDLY',
        CAMPAIGN_MONITOR = 'CAMPAIGN_MONITOR',
        CANNY = 'CANNY',
        CANVAS = 'CANVAS',
        CAPTERRA = 'CAPTERRA',
        CASTOR_DOC = 'CASTOR_DOC',
        CERTINIA = 'CERTINIA',
        CHAMELEON = 'CHAMELEON',
        CHANNEL_ADVISOR = 'CHANNEL_ADVISOR',
        CHARGEBEE = 'CHARGEBEE',
        CHARGEDESK = 'CHARGEDESK',
        CHARTMOGUL = 'CHARTMOGUL',
        CHORUS_AI = 'CHORUS_AI',
        CHURNKEY = 'CHURNKEY',
        CHURNZERO = 'CHURNZERO',
        CIMIS = 'CIMIS',
        CIN7 = 'CIN7',
        CIRCLE_CI = 'CIRCLE_CI',
        CLARI = 'CLARI',
        CLICKUP = 'CLICKUP',
        CLIFF_AI = 'CLIFF_AI',
        CLOSE = 'CLOSE',
        COLLIBRA = 'COLLIBRA',
        COMMERCETOOLS = 'COMMERCETOOLS',
        CONFLUENT_CLOUD = 'CONFLUENT_CLOUD',
        CONTRAST_SECURITY = 'CONTRAST_SECURITY',
        COUPA = 'COUPA',
        CRITEO = 'CRITEO',
        CROSSBEAM = 'CROSSBEAM',
        CRUNCHBASE = 'CRUNCHBASE',
        CUSTOMER_IO = 'CUSTOMER_IO',
        D2L_BRIGHTSPACE = 'D2L_BRIGHTSPACE',
        DATA_WORLD = 'DATA_WORLD',
        DATABAND = 'DATABAND',
        DATADOG = 'DATADOG',
        DATAEDO = 'DATAEDO',
        DATAFOLD = 'DATAFOLD',
        DATAGALAXY = 'DATAGALAXY',
        DATAHUB = 'DATAHUB',
        DB2 = 'DB2',
        DCL_LOGISTICS = 'DCL_LOGISTICS',
        DEAR = 'DEAR',
        DECUBE = 'DECUBE',
        DELIGHTED = 'DELIGHTED',
        DEPUTY = 'DEPUTY',
        DESTINI = 'DESTINI',
        DIXA = 'DIXA',
        DOCEBO = 'DOCEBO',
        DRATA = 'DRATA',
        DRIFT = 'DRIFT',
        DRIP = 'DRIP',
        DROPBOX = 'DROPBOX',
        DUOPLANE = 'DUOPLANE',
        E_CONOMIC = 'E_CONOMIC',
        EASY_POST = 'EASY_POST',
        ELASTICSEARCH = 'ELASTICSEARCH',
        ELEMENTARY = 'ELEMENTARY',
        ELLUCIAN = 'ELLUCIAN',
        ELOQUA = 'ELOQUA',
        EMAIL = 'EMAIL',
        EMPLOYMENT_HERO = 'EMPLOYMENT_HERO',
        EPICOR_PROPHET_21 = 'EPICOR_PROPHET_21',
        EVENTBRITE = 'EVENTBRITE',
        EVERHOUR = 'EVERHOUR',
        EXPENSIFY = 'EXPENSIFY',
        FACEBOOK = 'FACEBOOK',
        FIGMA = 'FIGMA',
        FIREBASE = 'FIREBASE',
        FLEXPORT = 'FLEXPORT',
        FORJ = 'FORJ',
        FOUNTAIN = 'FOUNTAIN',
        FREIGHTVIEW = 'FREIGHTVIEW',
        FRESHBOOKS = 'FRESHBOOKS',
        FRESHCHAT = 'FRESHCHAT',
        FRESHDESK = 'FRESHDESK',
        FRESHDESK_CONTACT_CENTER = 'FRESHDESK_CONTACT_CENTER',
        FRESHSERVICE = 'FRESHSERVICE',
        FRESHSUCCESS = 'FRESHSUCCESS',
        FRESHTEAM = 'FRESHTEAM',
        FRIENDBUY = 'FRIENDBUY',
        FRONT = 'FRONT',
        FTP = 'FTP',
        FULLSTORY = 'FULLSTORY',
        G2 = 'G2',
        GAINSIGHT = 'GAINSIGHT',
        GEM = 'GEM',
        GIT = 'GIT',
        GITLAB = 'GITLAB',
        GLADLY = 'GLADLY',
        GOCARDLESS = 'GOCARDLESS',
        GONG = 'GONG',
        GOOGLE = 'GOOGLE',
        GOOGLE_AD_MANAGER = 'GOOGLE_AD_MANAGER',
        GOOGLE_ADS = 'GOOGLE_ADS',
        GOOGLE_CALENDAR = 'GOOGLE_CALENDAR',
        GOOGLE_CAMPAIGN_MANAGER = 'GOOGLE_CAMPAIGN_MANAGER',
        GOOGLE_CHROME = 'GOOGLE_CHROME',
        GOOGLE_CLOUD = 'GOOGLE_CLOUD',
        GOOGLE_DISPLAY = 'GOOGLE_DISPLAY',
        GOOGLE_PLAY = 'GOOGLE_PLAY',
        GOOGLE_SEARCH_ADS_360 = 'GOOGLE_SEARCH_ADS_360',
        GOOGLE_SEARCH_CONSOLE = 'GOOGLE_SEARCH_CONSOLE',
        GOOGLE_TASKS = 'GOOGLE_TASKS',
        GORGIAS = 'GORGIAS',
        GREENHOUSE = 'GREENHOUSE',
        GREENPLUM = 'GREENPLUM',
        GREEN_POWER_MONITOR = 'GREEN_POWER_MONITOR',
        GREPSR = 'GREPSR',
        GRIN = 'GRIN',
        GURU = 'GURU',
        HARVEST = 'HARVEST',
        HDFS = 'HDFS',
        HEAP = 'HEAP',
        HEIGHT = 'HEIGHT',
        HELP_SCOUT = 'HELP_SCOUT',
        HELPSHIFT = 'HELPSHIFT',
        HEROKU_KAFKA = 'HEROKU_KAFKA',
        HEX = 'HEX',
        HIBOB = 'HIBOB',
        HOPIN = 'HOPIN',
        IMPACT = 'IMPACT',
        INCIDENT_IO = 'INCIDENT_IO',
        INFOBIP = 'INFOBIP',
        INSIGHTLY = 'INSIGHTLY',
        INSTAGRAM = 'INSTAGRAM',
        INTERCOM = 'INTERCOM',
        INVESTOPEDIA = 'INVESTOPEDIA',
        IRONCLAD = 'IRONCLAD',
        IRONSOURCE = 'IRONSOURCE',
        ITERABLE = 'ITERABLE',
        JAVA = 'JAVA',
        JENKINS = 'JENKINS',
        JUSTCALL = 'JUSTCALL',
        KANDJI = 'KANDJI',
        KENSU = 'KENSU',
        KHOROS_CARE = 'KHOROS_CARE',
        KISSMETRICS = 'KISSMETRICS',
        KLAVIYO = 'KLAVIYO',
        KNOWBE4 = 'KNOWBE4',
        KONNECT_INSIGHTS = 'KONNECT_INSIGHTS',
        KUSTOMER = 'KUSTOMER',
        LARIAT_DATA = 'LARIAT_DATA',
        LATTICE = 'LATTICE',
        LAUNCH_DARKLY = 'LAUNCH_DARKLY',
        LEARN_UPON = 'LEARN_UPON',
        LESSONLY = 'LESSONLY',
        LEVER = 'LEVER',
        LIFTOFF = 'LIFTOFF',
        LIGHTSPEED = 'LIGHTSPEED',
        LIGHTSUP = 'LIGHTSUP',
        LINEAR = 'LINEAR',
        LINKEDIN = 'LINKEDIN',
        LIVECHAT = 'LIVECHAT',
        LOADED_COMMERCE = 'LOADED_COMMERCE',
        LOB = 'LOB',
        LOOP = 'LOOP',
        LOOPIO = 'LOOPIO',
        LUMA = 'LUMA',
        MAGENTO = 'MAGENTO',
        MAILCHIMP = 'MAILCHIMP',
        MAILGUN = 'MAILGUN',
        MANDRILL = 'MANDRILL',
        MANTA = 'MANTA',
        MARIADB = 'MARIADB',
        MARIN_SOFTWARE = 'MARIN_SOFTWARE',
        MARKETO = 'MARKETO',
        MATTERMOST = 'MATTERMOST',
        MAVENLINK = 'MAVENLINK',
        MAXIO = 'MAXIO',
        MEDALLIA = 'MEDALLIA',
        META = 'META',
        METAPHOR = 'METAPHOR',
        METAPLANE = 'METAPLANE',
        MICROSOFT = 'MICROSOFT',
        MICROSOFT_AZURE_BLOB_STORAGE = 'MICROSOFT_AZURE_BLOB_STORAGE',
        MICROSOFT_DYNAMICS = 'MICROSOFT_DYNAMICS',
        MICROSOFT_ONEDRIVE = 'MICROSOFT_ONEDRIVE',
        MICROSOFT_PURVIEW = 'MICROSOFT_PURVIEW',
        MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
        MIXMAX = 'MIXMAX',
        MIXPANEL = 'MIXPANEL',
        MONDAY_COM = 'MONDAY_COM',
        MONGODB = 'MONGODB',
        MONTE_CARLO = 'MONTE_CARLO',
        MOUNTAIN = 'MOUNTAIN',
        MUX = 'MUX',
        NAMELY = 'NAMELY',
        NCINO = 'NCINO',
        NETSUITE_ANALYTICS = 'NETSUITE_ANALYTICS',
        NEW_RELIC = 'NEW_RELIC',
        NOTION = 'NOTION',
        NYLAS = 'NYLAS',
        OBSERVE = 'OBSERVE',
        OCTOPAI = 'OCTOPAI',
        OKTA = 'OKTA',
        OPEN_DATA_DC = 'OPEN_DATA_DC',
        OPEN_EXCHANGES_RATES = 'OPEN_EXCHANGES_RATES',
        OPEN_AI = 'OPEN_AI',
        OPEN_SEARCH = 'OPEN_SEARCH',
        OPTIMIZELY = 'OPTIMIZELY',
        ORBIT_COMMUNITY_PLATFORM = 'ORBIT_COMMUNITY_PLATFORM',
        ORDWAY = 'ORDWAY',
        ORTTO = 'ORTTO',
        OS_COMMERCE = 'OS_COMMERCE',
        OUTBRAIN = 'OUTBRAIN',
        OUTREACH = 'OUTREACH',
        OVALEDGE = 'OVALEDGE',
        PAGERDUTY = 'PAGERDUTY',
        PAGERSHIFT = 'PAGERSHIFT',
        PAYPAL = 'PAYPAL',
        PENDO = 'PENDO',
        PINGDOM = 'PINGDOM',
        PINTEREST = 'PINTEREST',
        PIPE17 = 'PIPE17',
        PIPEDRIVE = 'PIPEDRIVE',
        PIVOTAL_TRACKER = 'PIVOTAL_TRACKER',
        PLAYVOX = 'PLAYVOX',
        POSTHOG = 'POSTHOG',
        POWERSCHOOL = 'POWERSCHOOL',
        PRESTASHOP = 'PRESTASHOP',
        PRODUCTBOARD = 'PRODUCTBOARD',
        PYTHON = 'PYTHON',
        QUALAROO = 'QUALAROO',
        QUALTRICS = 'QUALTRICS',
        QUICKBOOKS = 'QUICKBOOKS',
        RAMP = 'RAMP',
        RARIBLE = 'RARIBLE',
        RECHARGE = 'RECHARGE',
        RECURLY = 'RECURLY',
        REDDIT = 'REDDIT',
        RELTIO = 'RELTIO',
        REPLY_IO = 'REPLY_IO',
        RETAILNEXT = 'RETAILNEXT',
        RETENTLY = 'RETENTLY',
        REVEL_SYSTEMS = 'REVEL_SYSTEMS',
        RINGCENTRAL = 'RINGCENTRAL',
        RIPPLING = 'RIPPLING',
        ROLLBAR = 'ROLLBAR',
        ROOTLY = 'ROOTLY',
        SAGE_HR = 'SAGE_HR',
        SAGE_INTACCT = 'SAGE_INTACCT',
        SAILTHRU = 'SAILTHRU',
        SALESLOFT = 'SALESLOFT',
        SALSIFY = 'SALSIFY',
        SAMSARA = 'SAMSARA',
        SAP = 'SAP',
        SAP_CONCUR = 'SAP_CONCUR',
        SCORM = 'SCORM',
        SECODA = 'SECODA',
        SECURITY_JOURNEY = 'SECURITY_JOURNEY',
        SELECT_STAR = 'SELECT_STAR',
        SENDGRID = 'SENDGRID',
        BREVO = 'BREVO',
        SERVICE_NOW = 'SERVICE_NOW',
        SFTP = 'SFTP',
        SHAREPOINT = 'SHAREPOINT',
        SHARETRIBE = 'SHARETRIBE',
        SHIPHERO = 'SHIPHERO',
        SHIPSTATION = 'SHIPSTATION',
        SHOPIFY = 'SHOPIFY',
        SHOPWARE = 'SHOPWARE',
        SHORT_IO = 'SHORT_IO',
        SIFFLET = 'SIFFLET',
        SIMPLECAST = 'SIMPLECAST',
        SIMPLESAT = 'SIMPLESAT',
        SINGLESTORE = 'SINGLESTORE',
        SISENSE = 'SISENSE',
        SKILLJAR = 'SKILLJAR',
        SKUVAULT = 'SKUVAULT',
        SLAB = 'SLAB',
        SMADEX = 'SMADEX',
        SNAPCHAT = 'SNAPCHAT',
        SNOWPLOW = 'SNOWPLOW',
        SODA = 'SODA',
        SONARQUBE = 'SONARQUBE',
        SPLIT_IO = 'SPLIT_IO',
        SPLUNK = 'SPLUNK',
        SPOTIFY = 'SPOTIFY',
        SPREE_COMMERCE = 'SPREE_COMMERCE',
        SPROUT_SOCIAL = 'SPROUT_SOCIAL',
        SQUARE = 'SQUARE',
        SQUARESPACE = 'SQUARESPACE',
        STACK_ADAPT = 'STACK_ADAPT',
        STATSIG = 'STATSIG',
        STRIPE = 'STRIPE',
        STYLIGHT = 'STYLIGHT',
        SUBSCRIPT = 'SUBSCRIPT',
        TABOOLA = 'TABOOLA',
        TALKDESK = 'TALKDESK',
        TALKWALKER = 'TALKWALKER',
        TEAMWORK_COM = 'TEAMWORK_COM',
        TEMPO_IO = 'TEMPO_IO',
        TERADATA = 'TERADATA',
        TERRAFORM = 'TERRAFORM',
        THE_TRADE_DESK = 'THE_TRADE_DESK',
        THOUGHT_SPOT = 'THOUGHT_SPOT',
        TIKTOK = 'TIKTOK',
        TOGGL = 'TOGGL',
        TRANSCEND = 'TRANSCEND',
        TRELLO = 'TRELLO',
        TRISOLUTE = 'TRISOLUTE',
        TWILIO = 'TWILIO',
        TYPEFORM = 'TYPEFORM',
        UPLAND = 'UPLAND',
        USERVOICE = 'USERVOICE',
        VALIDIO = 'VALIDIO',
        VEEVA = 'VEEVA',
        VERIZON_MEDIA = 'VERIZON_MEDIA',
        VITALLY = 'VITALLY',
        VONAGE = 'VONAGE',
        VUETIFY = 'VUETIFY',
        WEB_SCRAPER = 'WEB_SCRAPER',
        WEBHOOKS = 'WEBHOOKS',
        WHEN_I_WORK = 'WHEN_I_WORK',
        WHEREFOUR = 'WHEREFOUR',
        WIKIPEDIA = 'WIKIPEDIA',
        WOOCOMMERCE = 'WOOCOMMERCE',
        WORDPRESS = 'WORDPRESS',
        WORKABLE = 'WORKABLE',
        WORKDAY = 'WORKDAY',
        WORKFLOW_MAX = 'WORKFLOW_MAX',
        WRIKE = 'WRIKE',
        X = 'X',
        XERO = 'XERO',
        XRAY = 'XRAY',
        YAHOO = 'YAHOO',
        YOTPO = 'YOTPO',
        YOUTUBE = 'YOUTUBE',
        ZEENEA = 'ZEENEA',
        ZEN_CART = 'ZEN_CART',
        ZENDESK_CHAT = 'ZENDESK_CHAT',
        ZENDESK_SELL = 'ZENDESK_SELL',
        ZENDESK_SUNSHINE = 'ZENDESK_SUNSHINE',
        ZENDESK_SUPPORT = 'ZENDESK_SUPPORT',
        TRINET = 'TRINET',
        ZINGTREE = 'ZINGTREE',
        ZOHO_BOOKS = 'ZOHO_BOOKS',
        ZOHO_CAMPAIGNS = 'ZOHO_CAMPAIGNS',
        ZOHO_CRM = 'ZOHO_CRM',
        ZOOM = 'ZOOM',
        ZUORA = 'ZUORA',
    }

    export enum usage {
        UNSUPPORTED = 'UNSUPPORTED',
        LOW = 'LOW',
        MEDIUM = 'MEDIUM',
        HIGH = 'HIGH',
    }


}
