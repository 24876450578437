<script setup lang="ts">import { computed as _computed } from 'vue';

import Vue from 'vue';
import store from '@/store';
import authModule from '@/store/modules/auth';
import catalogModule from '@/store/modules/catalog';
import { getModule } from 'vuex-module-decorators';
import titleCase from '@/utils/filters/titleCase';
import { type DomainUiLightDto } from '@/api';

const auth = getModule(authModule, store);
const catalog = getModule(catalogModule, store);

const selectedDomain = _computed(() => auth.getDomain);
const items = _computed(() => auth.getUserDomains);

const setDomain = (domain: DomainUiLightDto) => {
  auth.setDomain(domain);
  Vue.nextTick(() => {
    catalog.fetchData();
  });
};
</script>

<template lang="pug">
v-select(
  :items="items"
  :value="selectedDomain"
  @change="setDomain"
  item-text="name"
  item-value="name"
  hide-details
  dense
  outlined
  return-object
  style="max-width: 200px"
  :menu-props="{ bottom: true, offsetY: true, 'min-width': '240px', left: true, 'nudge-top':'-10' }")
  template( v-slot:selection="{ item }")
    .d-flex.align-center
      v-icon.mr-2( small) icon-building
      span.text-truncate( style="width: 120px;" :title="$tc('domains.all_datasets')" v-if="item.name==='All'") {{ $t('domains.all_datasets') }}
      span.text-truncate( style="width: 120px;" :title="item.name" v-else) {{ item.name }}

  template( v-slot:item="{ item }")
   v-row( no-gutters )
    v-col
      div.text-truncate( style="width: 120px;" :title="item.name" ) {{ item.name }}
    v-spacer
    v-col
      span.text-truncate( style="width: 60px;" :title="item.role" ) {{ titleCase(item.role) }}

</template>
