<script setup lang="ts">import { computed as _computed } from 'vue';

import {
  AssetDto,
  type AssetSchemaDto,
} from '@/api';
import DatasetFieldChip from '@/modules/monitors/monitor/common/DatasetFieldChip.vue';
import DataQualityChip from '@/modules/catalog/asset/data-quality/DataQualityChip.vue';
import { type QualityItem, remapStatus } from '@/modules/catalog/asset/data-quality/data-quality';

interface AssetOverviewSchemaProps {
  schema: AssetSchemaDto;
  urn: AssetDto['urn'];
}

const props = defineProps({
  schema: null,
  urn: null
});

const fields = _computed(() => {
  const schema = props.schema?.fields ? [...props.schema.fields] : [];
  return schema?.map((field) => ({
    name: field.name,
    typeCategory: field.typeCategory,
    dataQuality: remapStatus(field.ruleStatuses),
  })).sort((a: any, b: any) => a.name.localeCompare(b.name));
});

const fieldsCount = _computed(() => props.schema?.fields?.length ?? 0);
const to = _computed(() => ({
  name: 'data-catalog.asset.schema',
  params: { urn: props.urn },
}));

const rowColorClass = (dataQuality: QualityItem) => {
  if (!dataQuality) return '';
  if (dataQuality.failed) return 'bgDangerPrimary';
  if (dataQuality.needsAttention) return 'bgWarningPrimary';
  return '';
};
</script>

<template lang="pug">
v-card( outlined class="my-4 pa-4" min-height="300px" data-cy="asset_columns" )
  v-row
    v-col( cols="8" )
      .text-h6.font-weight-medium {{ $t('assets.columns') }} ({{ fieldsCount }})
    v-col( cols="4" class="text-right" )
      router-link.link( :to="to" ) {{ $t('assets.see_details') }}
  v-row.mx-n4( v-for="(field, index) in fields" :key="index" :class="rowColorClass(field.dataQuality)")
    v-col( cols="9" )
      DatasetFieldChip( :name="field.name" :type="field.typeCategory" color="textPrimary" data-cy="asset_column_field_name" )
    v-col( cols="3" )
      .d-flex.justify-end
        DataQualityChip( v-if="field.dataQuality" :data-quality="field.dataQuality" )
</template>
