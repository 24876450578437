import {
  AssetHeaderDto,
  FieldLevelSearchDto,
  IncidentDetailDto,
  RuleInfoDto,
  LastIngestionStatusDto,
  LastRunStatusDto,
  RuleStatusDto,
} from '@/api';
import titleCase from '@/utils/filters/titleCase';
import colors from '@/plugins/colors';

export type Health =
  | 'UNKNOWN'
  | LastIngestionStatusDto.status
  | LastRunStatusDto.status
  | FieldLevelSearchDto.health
  | AssetHeaderDto.qualityStatus
  | RuleInfoDto.lastRunStatus
  | IncidentDetailDto.status
  | RuleStatusDto.ruleStatus
  | IncidentDetailDto.qualification;

/**
 * Returns a color corresponding the specified health
 * @param {string} health - The health
 * @param {boolean} hexValue - Whether to return the hex value or the color name
 * @return string - The correct color based on the health
 */
export function createColor(health: Health, hexValue = false) {
  switch (health) {
    case RuleInfoDto.lastRunStatus.SUCCESS:
    case LastRunStatusDto.status.SUCCESS:
    case FieldLevelSearchDto.health.HEALTHY:
    case RuleInfoDto.lastRunStatus.RUNNING:
    case LastIngestionStatusDto.status.RUNNING:
    case LastIngestionStatusDto.status.SUCCESS:
    case LastRunStatusDto.status.RUNNING:
    case IncidentDetailDto.qualification.FIXED:
    case IncidentDetailDto.qualification.FALSE_POSITIVE:
    case IncidentDetailDto.status.CLOSED:
    case RuleStatusDto.ruleStatus.PASSING:
      return hexValue ? colors.green.lighten1 : 'iconSuccess';

    case RuleInfoDto.lastRunStatus.FAILED:
    case LastRunStatusDto.status.FAILED:
    case FieldLevelSearchDto.health.CRITICAL:
    case LastIngestionStatusDto.status.FAILURE:
    case IncidentDetailDto.status.OPEN:
    case RuleStatusDto.ruleStatus.FAILING:
      return hexValue ? colors.red.base : 'iconDanger';

    case FieldLevelSearchDto.health.NOT_SUPPORTED:
    case IncidentDetailDto.qualification.NO_ACTION_NEEDED:
    case IncidentDetailDto.qualification.DUPLICATE:
    case 'UNKNOWN':
      return hexValue ? colors.grey.lighten3 : 'iconNeutral';

    case RuleInfoDto.lastRunStatus.REQUIRES_YOUR_ATTENTION:
    case LastRunStatusDto.status.REQUIRES_YOUR_ATTENTION:
    case FieldLevelSearchDto.health.AT_RISK:
      return hexValue ? colors.orange.lighten1 : 'iconAttention';

    case RuleInfoDto.lastRunStatus.TECHNICAL_ERROR:
    case RuleStatusDto.ruleStatus.NEEDS_ATTENTION:
    case LastRunStatusDto.status.TECHNICAL_ERROR:
    case IncidentDetailDto.status.IN_PROGRESS:
      return hexValue ? colors.yellow.base : 'iconWarning';

    case RuleInfoDto.lastRunStatus.PENDING:
    case LastRunStatusDto.status.PENDING:
    case LastIngestionStatusDto.status.PENDING:
    case FieldLevelSearchDto.health.UNMONITORED:
    case LastIngestionStatusDto.status.SKIPPED_DATASOURCE_ALREADY_RUNNING:
    case RuleStatusDto.ruleStatus.NOT_EVALUATED:
      return hexValue ? colors.grey.base : 'iconNeutral';

    default:
      return hexValue ? colors.blue.base : 'iconAi';
  }
}

/**
 * Returns a description corresponding the specified health
 * @param {string} health - The health
 * @return string - The correct description based on the health
 */
export function createText(health: Health) {
  switch (health) {
    case 'UNKNOWN':
      return 'No previous runs';
    case FieldLevelSearchDto.health.CRITICAL:
      return 'The latest execution of one or more critical related rules has failed';
    case FieldLevelSearchDto.health.AT_RISK:
      return 'An execution of at least one related rule has failed';
    case FieldLevelSearchDto.health.HEALTHY:
      return 'All past executions of related rules are successful';
    case FieldLevelSearchDto.health.UNMONITORED:
      return 'The data asset is not being monitored';
    default:
      return titleCase(health);
  }
}
