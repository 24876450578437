/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RuleStatusDto } from './RuleStatusDto';

export type LightSchemaFieldDto = {
    monitored?: boolean;
    name: string;
    ruleStatuses: Array<RuleStatusDto>;
    type: string;
    typeCategory?: LightSchemaFieldDto.typeCategory | null;
};

export namespace LightSchemaFieldDto {

    export enum typeCategory {
        DATE = 'DATE',
        TIME = 'TIME',
        DATETIME = 'DATETIME',
        BOOLEAN = 'BOOLEAN',
        STRING = 'STRING',
        INTEGER = 'INTEGER',
        FLOAT = 'FLOAT',
        ARRAY = 'ARRAY',
        JSON = 'JSON',
        STRUCT = 'STRUCT',
        MAP = 'MAP',
        BINARY = 'BINARY',
        OTHER = 'OTHER',
    }


}
