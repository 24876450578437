import { BackgroundColors } from '@/@types/types';
import {
  SUB_ROUTES,
} from '@/router/components';
import { UserUiDto } from '@/api';

export default {
  path: 'sso',
  name: 'settings.sso',
  meta: {
    requiresAuth: true,
    roles: [UserUiDto.role.ADMIN],
    breadCrumb: 'app.navigation-drawer.sso',
    background: BackgroundColors.SECONDARY,
  },
  component: SUB_ROUTES.SETTINGS_SSO,
};
