<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import Vue from 'vue';
import { useRouter } from 'vue-router/composables';
import { getModule } from 'vuex-module-decorators';
import {
  RuleInfoDto,
  RuleService,
  WorkspaceService,
  GetCollaborationToolItemDto,
  RuleQualifyDto,
} from '@/api';
import store from '@/store';
import i18n from '@/i18n';
import authModule from '@/store/modules/auth';
import eventBus, { EventType } from '@/utils/eventBus';
import MonitorsYamlModal from '@/components/monitors/Monitors-Yaml-Modal.vue';
import copyToClipboard from '@/utils/copyToClipboard';
import IncidentsForm from '@/components/incidents/Incidents-Form.vue';
import MonitorsIncidentLinkForm from '@/components/monitors/MonitorsIncidentLinkForm.vue';
import ProviderIcons from '@/utils/provider-icons';
import SSplitButton, { type SSplitButtonItem } from '@/components/SSplitButton/SSplitButton.vue';
import getSettingsFromMonitorType from '@/modules/monitors/monitor-wizard/monitor-configurations/monitor-configurations';

const jiraIcon = ProviderIcons.ATLASSIAN_JIRA;

interface RuleOverviewInfoActionsProps {
  rule: RuleInfoDto;
  jiraIssue?: GetCollaborationToolItemDto;
}
const props = defineProps({
  rule: null,
  jiraIssue: null
});

interface RuleOverviewInfoActionsEmits {
  (event: 'update'): void;
  (event: 'action'): void;
}
const emits = defineEmits(["update", "action"]);

const router = useRouter();

const auth = getModule(authModule, store);

const MonitorsYamlModalRef = _ref<MonitorsYamlModal | null>(null);
const incidentsFormRef = _ref<typeof IncidentsForm | null>(null);
const monitorsIncidentLinkFormRef = _ref<typeof MonitorsIncidentLinkForm | null>(null);

const isReadOnly = _computed(() => props.rule.readOnly);
const canManuallyRun = _computed(() => props.rule.canManuallyRun);
const canDelete = _computed(() => auth.userActions['monitoring.monitor.write'] && !isReadOnly.value);
const canEdit = _computed(() => auth.userActions['monitoring.monitor.write'] && !isReadOnly.value);
const canRun = _computed(() => auth.userActions['monitoring.monitor.write'] && canManuallyRun.value);
const canGenerateYaml = _computed(() => props.rule.supportAsCodeYAMLConversion);
const hasIncident = _computed(() => !!props.rule.lastUnresolvedIncident);
const hasRule = _computed(() => props.rule !== null);
const buttonMessage = _computed(() => {
  if (!canManuallyRun.value) return i18n.t('monitors.rule_cant_run');
  return '';
});
const bookmark = _computed(() => ({
  entityId: props.rule.id,
  entityType: 'SIFFLET_RULE',
}));

const incidentSubActions = _computed<SSplitButtonItem[] | null>(() => {
  if (!props.jiraIssue?.itemKey) return null;

  return [
    {
      icon: jiraIcon,
      text: i18n.t('alerts_destinations.jira.issue_modal_cta_issue', { issue: props.jiraIssue?.itemKey }),
      to: props.jiraIssue?.itemUrl,
    },
  ];
});

const canBeQualified = _computed(() => auth.userActions['monitoring.monitor.responder']);
const hasCanQualify = _computed(() => props.rule.canBeQualified && props.rule.ruleStatus === RuleInfoDto.ruleStatus.FAILING);

const qualifyAsPassing = async (qualification: RuleQualifyDto.qualification = RuleQualifyDto.qualification.REVIEWED) => RuleService.qualifyRuleDatapoints({
  id: props.rule.id,
  requestBody: {
    qualification,
  },
}).then(() => {
  Vue.notify({
    text: i18n.t('monitors.qualify_passed'),
  });
}).finally(() => {
  emits('update');
  eventBus.$emit(EventType.REFRESH_RULE_INFO);
  eventBus.$emit(EventType.REFRESH_RULE_GROUPS);
});

const canClone = _computed(() => getSettingsFromMonitorType(props.rule.ruleTemplateName as any)?.canClone !== false);

const qualificationActions = _computed<SSplitButtonItem[]>(() => [
  {
    text: i18n.t('monitors.qualify_as_passing_no_action'),
    onClick: () => qualifyAsPassing(RuleQualifyDto.qualification.NO_ACTION_NEEDED),
  },
  {
    text: i18n.t('monitors.qualify_as_passing_false_positive'),
    onClick: () => qualifyAsPassing(RuleQualifyDto.qualification.FALSE_POSITIVE),
  },
]);

const qualify = () => qualifyAsPassing(RuleQualifyDto.qualification.REVIEWED);

const editRuleLocation = _computed(() => ({
  name: 'monitors.rule.edit',
  params: {
    id: props.rule.id,
  },
}));
const incidentLocation = _computed(() => ({
  name: 'incidents.incident.incident_overview',
  params: {
    issue: props.rule.lastUnresolvedIncident?.issueNo,
  },
}));

const deleteRule = async () => {
  if (window.confirm('Are you sure you want to delete this rule')) {
    await RuleService.deleteSiffletRuleById({ id: props.rule.id });
    Vue.notify({
      text: `Rule ${props.rule.name} has been deleted!`,
    });
    router.push({
      name: 'monitors',
    });
  }
};

const copyIDToClipboard = () => {
  copyToClipboard(props.rule.id, `Monitor ID copied to clipboard <br> ${props.rule.id}`);
};

const generateYaml = async () => {
  const rule = await RuleService.getSiffletRuleById({ id: props.rule.id });
  const yamlCode = await WorkspaceService.convertMonitorToCode({ requestBody: rule }) as any;

  MonitorsYamlModalRef.value?.setCode(yamlCode);
  MonitorsYamlModalRef.value?.open();
};

const createSingleIncident = () => {
  incidentsFormRef.value?.handleSingleIncidentCreation(props.rule);
};

const linkIncident = () => {
  monitorsIncidentLinkFormRef.value?.handleMonitorsIncidentLinking([props.rule]);
};

const onLinkIncident = () => {
  emits('update');
};

const cloneMonitor = () => {
  router.push({
    name: 'monitors.rule.clone',
    params: {
      id: props.rule.id,
    },
  });
};

const manualRun = async () => {
  Vue.notify({
    text: 'Manual Run has started!',
  });

  await RuleService.siffletRuleManualRun({ id: props.rule.id });

  eventBus.$emit(EventType.REFRESH_RULE_RUNS);
};
</script>

<template lang="pug">

.rule-overview-info-actions.ml-12

  MonitorsYamlModal( ref="MonitorsYamlModalRef" )

  IncidentsForm( ref="incidentsFormRef" )

  MonitorsIncidentLinkForm(
    ref="monitorsIncidentLinkFormRef"
    @onLink="onLinkIncident")

  .d-flex.flex-nowrap( v-if="hasRule" )

    SSplitButton.ml-2(
      v-if="hasCanQualify"
      :async-action="qualify"
      :disabled="!canBeQualified"
      icon="icon-shield-check"
      :text="$t('monitors.qualify_as_passing')"
      color="primary"
      :items="qualificationActions"
      data-cy-button="qualify-as-passing-button"
      data-cy-menuButton="qualify-as-passing-menu-buttons"
    )

    SButton.ml-2(
      v-if="hasIncident && !incidentSubActions"
      icon="icon-flag"
      :text="$t('monitors.edit.view_incident')"
      :to="incidentLocation"
      color="secondary"
      variant="outlined"
      :items="incidentSubActions"
      data-cy="incident-button"
    )

    SSplitButton.ml-2(
      v-if="hasIncident && incidentSubActions"
      icon="icon-flag"
      :text="$t('monitors.edit.view_incident')"
      :to="incidentLocation"
      color="secondary"
      variant="outlined"
      :items="incidentSubActions"
      data-cy-button="incident-split-button"
      data-cy-menuButton="incident-split-menu-buttons"
    )

    SButton.ml-2(
      :to="editRuleLocation"
      :disabled="!canEdit"
      icon="icon-edit"
      :tooltip="rule.readOnly ? $t('monitors.rule_cant_be_edited') : null"
      color="secondary"
      variant="outlined"
    )

    SButton.ml-2(
      :disabled="!canRun"
      icon="icon-play-circle"
      :tooltip="buttonMessage"
      :async-action="manualRun"
      variant="outlined"
      color="secondary"
    )

    SThreeDotMenu.ml-2
      SMenuButton(
        @click="copyIDToClipboard"
        icon="icon-copy"
        :text="$t('rules.copy_id')" )

      SMenuButton(
        :disabled="!canClone"
        @click="cloneMonitor"
        icon="icon-copy-add"
        :text="$t('rules.clone')" )

      SMenuButton(
        @click="linkIncident"
        data-cy="monitors-result-card-menu-link-incident"
        icon="icon-flag-add"
        :text="$t('monitors.edit.link_existing_incident')")

      SMenuButton(
        @click="createSingleIncident"
        icon="icon-add"
        :text="$t('monitors.create_incident')")

      SMenuButton(
        v-if="canGenerateYaml"
        @click="generateYaml"
        icon="icon-code-brackets"
        :text="$t('monitors.show_as_yaml_code')" )

      Bookmark( :bookmark="bookmark" is-menu )

      SMenuButton(
        :disabled="!canDelete"
        icon="icon-trash"
        :text="$t('monitors.delete')"
        @click="deleteRule"
        color="danger"
        variant="outlined")

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    type="actions"
    width="500" )

</template>
