/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AssetSelectionDto } from './AssetSelectionDto';
import type { BigqueryIntegrationParamsDto } from './BigqueryIntegrationParamsDto';
import type { QlikIntegrationParamsDto } from './QlikIntegrationParamsDto';
import type { RedshiftIntegrationParamsDto } from './RedshiftIntegrationParamsDto';
import type { SnowflakeIntegrationParamsDto } from './SnowflakeIntegrationParamsDto';

export type CreateIntegrationRequestDto = {
    assetSelectionDto?: AssetSelectionDto;
    cronExpression?: string;
    name: string;
    params: (BigqueryIntegrationParamsDto | QlikIntegrationParamsDto | RedshiftIntegrationParamsDto | SnowflakeIntegrationParamsDto);
    secretId?: string;
    type: CreateIntegrationRequestDto.type;
};

export namespace CreateIntegrationRequestDto {

    export enum type {
        ATHENA = 'ATHENA',
        BIGQUERY = 'BIGQUERY',
        REDSHIFT = 'REDSHIFT',
        SNOWFLAKE = 'SNOWFLAKE',
        DATABRICKS = 'DATABRICKS',
        MSSQL = 'MSSQL',
        MYSQL = 'MYSQL',
        POSTGRES = 'POSTGRES',
        ORACLE = 'ORACLE',
        SYNAPSE = 'SYNAPSE',
        POWER_BI = 'POWER_BI',
        LOOKER = 'LOOKER',
        TABLEAU = 'TABLEAU',
        QUICKSIGHT = 'QUICKSIGHT',
        MICROSTRATEGY = 'MICROSTRATEGY',
        QLIK = 'QLIK',
        AIRFLOW = 'AIRFLOW',
        MWAA = 'MWAA',
        COMPOSER = 'COMPOSER',
        DBT = 'DBT',
        DBTCLOUD = 'DBTCLOUD',
        FIVETRAN = 'FIVETRAN',
        DECLARATIVE = 'DECLARATIVE',
        _UNKNOWN_ = '_UNKNOWN_',
    }


}
