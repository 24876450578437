<script setup lang="ts">import { computed as _computed } from 'vue';

/**
 *
 * Monitor common header component, to be used in monitor list and monitor details
 * Displays name, status, datasets, monitor mode and generic infos
 *
 */
import { type RawLocation } from 'vue-router';
import src from '@/utils/filters/src';
import i18n from '@/i18n';
import fromNow from '@/utils/filters/fromNow';
import {
  MonitorModeEnum,
  MonitorHeaderDisplayMode,
  type MonitoredDatasetLightDto,
  type MonitorAggregatedData,
} from '@/modules/monitors/@types/monitor-types';
import { ThresholdModeEnum } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';
import {
  parseMonitoredDatasets,
  parseMonitorMode,
  parseMonitorThresholdMode,
  deduplicateMonitoredDatasets,
} from '@/modules/monitors/helpers/monitor-helpers';
import MonitorMode from '@/modules/monitors/monitor/common/MonitorMode.vue';
import MonitorIcon from '@/modules/monitors/monitor/common/MonitorIcon.vue';
import ThresholdMode from '@/modules/monitors/monitor/common/ThresholdMode.vue';
import RunStatus from '@/modules/monitors/monitor/common/RunStatus.vue';
import Severity from '@/components/Severity.vue';
import SChipBasic from '@/components/SChipBasic.vue';
import SIcon from '@/components/SIcon.vue';
import DatasetFieldChip from '@/modules/monitors/monitor/common/DatasetFieldChip.vue';
import TagsLight from '@/components/tags/TagsLight.vue';
import TermsLight from '@/components/terms/TermsLight.vue';
import MonitorStatus from '@/components/icons/Monitor-Status.vue';
import MonitorHeaderAiSuggestionTooltip from '@/modules/monitors/monitor/common/MonitorHeaderAiSuggestionTooltip.vue';

interface MonitorHeaderProps {
  monitorAggregatedData: MonitorAggregatedData | null; // Mandatory, but can be null
  displayMode?: MonitorHeaderDisplayMode;
  incidentData?: {
    firstFailureDate?: number;
    lastFailureDate?: number;
    numberOfFailures: number;
  };
}
const props = defineProps({
  monitorAggregatedData: null,
  displayMode: { default: MonitorHeaderDisplayMode.LIST },
  incidentData: null
});

const hasData = _computed(() => !!props.monitorAggregatedData?.id);

const displayAsList = _computed(() => props.displayMode === MonitorHeaderDisplayMode.LIST);
const monitorMode = _computed((): MonitorModeEnum | undefined => (props.monitorAggregatedData ? parseMonitorMode(props.monitorAggregatedData.ruleTemplateName, props.monitorAggregatedData.ruleParams) : undefined));
const thresholdMode = _computed((): ThresholdModeEnum | undefined => {
  if (props.monitorAggregatedData && props.monitorAggregatedData.ruleParams) {
    return parseMonitorThresholdMode(props.monitorAggregatedData.ruleParams);
  }
  return undefined;
});
const datasets = _computed((): MonitoredDatasetLightDto[] => {
  if (props.monitorAggregatedData && props.monitorAggregatedData.datasets) {
    return deduplicateMonitoredDatasets(parseMonitoredDatasets(props.monitorAggregatedData.datasets, props.monitorAggregatedData.monitoredDatasetFields ?? []));
  }
  return [];
});

const orderedDatasets = _computed(() => datasets.value.toSorted((a, b) => a.datasourceName.localeCompare(b.datasourceName)));
const lastRunFromNow = _computed(() => (props.monitorAggregatedData?.lastRunTimestamp ? fromNow(props.monitorAggregatedData.lastRunTimestamp) : i18n.t('assets.no_past_runs')));
const hasIncidentData = _computed(() => !!props.incidentData);
const firstFailureDate = _computed(() => fromNow(props.incidentData?.firstFailureDate));
const lastFailureDate = _computed(() => fromNow(props.incidentData?.lastFailureDate));
const chipSize = _computed(() => (displayAsList.value ? 'small' : 'default'));

/**
 * Returns asset overview route for router-link, from the given uniform resource name
 * @param urn resource name
 */
const assetRoute = (urn: string | undefined): RawLocation => ({
  name: 'data-catalog.asset.overview',
  params: { urn: urn || '' },
});

</script>

<template lang="pug">
.monitor-header
  v-row( v-if="hasData" no-gutters )
    v-col.flex-grow-0
      MonitorIcon.mr-2( class="waitForRender" :size="displayAsList ? 'medium' : 'x-large'" :type="monitorAggregatedData?.sourcePlatform")

    v-col( class="flex-fix" )
      .d-flex.justify-space-between.align-top
        div.monitor-name.text-truncate

          span.overline.grey--text.d-block(
            v-if="monitorAggregatedData?.ruleTemplateName"
            data-cy="monitor-header-overline"
          ) {{ $t(`monitors.templates.${monitorAggregatedData.ruleTemplateName}.name`) }}

          .d-flex.align-center
            h2.font-weight-medium.text-truncate.mt-0(
              data-cy="monitor-header-name"
              :class="displayAsList ? 'text-h6' : 'text-h5'"
            )  {{ monitorAggregatedData?.name }}
            MonitorHeaderAiSuggestionTooltip(
              v-if="displayAsList && monitorAggregatedData?.hasAiRecommendations"
              :monitorId="monitorAggregatedData.id"
            )

      .d-flex.align-center.flex-wrap.mb-1
        RunStatus.mr-2( :status="monitorAggregatedData?.ruleStatus" data-cy="monitor-header-status" )
        //- Monitor run status history
        .d-inline-flex.align-center.mr-4( v-if="displayAsList && !hasIncidentData" )
          SIcon.mr-1( icon="icon-arrow-history" color="iconNeutral" size="small" )
          MonitorStatus( data-cy="monitors-result-card-run-statuses" :status="monitorAggregatedData?.lastWeekStatuses" )

        //- Datasets
        .mr-3.d-flex.align-center.flex-wrap( v-for="(dataset, index) in orderedDatasets" :key="index" )
          v-tooltip( top )
            template( v-slot:activator="{ on }" )
              v-avatar(tile size="20" class="flex-grow-0 flex-shrink-0 mr-1" v-on="on")
                img( :src="src(dataset.datasourceType, 'datasources')" :alt="dataset.datasourceType" )
            span {{ dataset.datasourceName }}

          router-link.mr-2.link( :to="assetRoute(dataset.urn)" ) {{ dataset.name }}

          DatasetFieldChip.mr-2(
            v-for="field in dataset.datasetFields"
            :key="field.id"
            :name="field.monitoringName"
            :type="field.typeCategory"
            :size="chipSize"
          )

      .d-flex.align-center.flex-wrap.mb-1( v-if="hasIncidentData" )
        SChipBasic.mr-4(
          icon="icon-chevron-left-sharp-equal"
          :text="`${$t('monitors.first_failure')}: ${firstFailureDate}`"
          size="small"
          data-cy="monitors-result-card-first-failure"
        )
        SChipBasic.mr-4(
          icon="icon-chevron-right-sharp-equal"
          :text="`${$t('monitors.last_failure')}: ${lastFailureDate}`"
          size="small"
          data-cy="monitors-result-card-first-failure"
        )

      .d-flex.align-center.flex-wrap.mb-1
        MonitorMode.mr-4( :mode='monitorMode' :size="chipSize" )
        ThresholdMode.mr-4( :mode='thresholdMode' :size="chipSize" )
        SChipBasic.mr-4(
          v-if="monitorAggregatedData?.ruleParams?.hasGroupBy"
          :text="$tc('monitors.group_by', monitorAggregatedData?.ruleParams?.groupByFields?.length)"
          icon="icon-shapes"
          :size="chipSize"
        )

        template( v-if="displayAsList")
          SChipBasic.mr-4(
            icon="icon-play-circle"
            :text="lastRunFromNow"
            size="small"
          )
          Severity.mr-4(
            :value="monitorAggregatedData?.criticality"
            size="small"
          )
          TagsLight.mr-4(
            v-if="monitorAggregatedData?.tags?.length"
            :tags="monitorAggregatedData?.tags"
            size="small"
          )
          TermsLight.mr-4(
            v-if="monitorAggregatedData?.terms?.length"
            :terms="monitorAggregatedData?.terms"
            size="small"
          )
          AvatarList(
            v-if="monitorAggregatedData?.createdBy"
            :value="[monitorAggregatedData?.createdBy]" size="16"
          )

    v-col.flex-grow-0
      slot( name="actions" )

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    type="list-item-avatar-two-line"
    width="500" )

</template>

<style lang="scss" scoped>
.flex-fix {
  min-width: 0;
}
</style>
