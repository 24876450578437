import { BackgroundColors } from '@/@types/types';
import {
  SUB_ROUTES,
} from '@/router/components';
import { UserUiDto } from '@/api';

export default {
  path: 'ai',
  name: 'settings.ai',
  meta: {
    requiresAuth: true,
    roles: [UserUiDto.role.ADMIN],
    breadCrumb: 'app.navigation-drawer.ai',
    background: BackgroundColors.SECONDARY,
  },
  component: SUB_ROUTES.SETTINGS_AI,
};
