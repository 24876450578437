/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FilterConditionGroupDto } from './FilterConditionGroupDto';

export type DomainDynamicInputDto = {
    filterConditionGroups: Array<FilterConditionGroupDto>;
    filterLogicalOperator: DomainDynamicInputDto.filterLogicalOperator;
};

export namespace DomainDynamicInputDto {

    export enum filterLogicalOperator {
        AND = 'AND',
        OR = 'OR',
    }


}
