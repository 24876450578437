<template lang="pug">
.menu-item
  v-list-item( class="logo mt-2" link to="/dashboard" data-cy="logo" @click.stop)

    v-list-item-icon
      img(
        width="32"
        height="32"
        :src="`/next/images/logo/logo_just_icon.svg`"
        :alt="$t('common.words.sifflet_logo')" )

    v-list-item-title.d-flex
      img(
        width="73"
        height="32"
        :src="`/next/images/logo/logo_just_letters.svg`"
        :alt="$t('common.words.sifflet_logo')" )

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Logo extends Vue {
}
</script>

<style lang="scss" scoped>

.logo {
  &.v-list-item--active {
    background-color: transparent;
    &::before,
    &::after {
      opacity: 0;
    }
  }
}
</style>
