<template lang="pug">
v-row
  v-col( cols="12" :style="{'min-height': height}" )
    .d-flex.flex-column.justify-center.align-center( style="height: 100%;" )
      v-btn(fab depressed)
        v-icon.tertiary--text( size="36" medium color="iconNeutral") {{ icon }}
      b.text-h6.mt-2 {{ text }}
      span.text-center.break-spaces.mt-2( v-if="secondaryText" ) {{ secondaryText }}
      slot( name="secondary" )
      v-btn.mt-4(color="primary" v-if="btnText" :to="btnLink" :disabled="disabled")
        v-icon( small ) {{ btnIcon }}
        .ml-1 {{ btnText }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class NoDataAvailablePlaceholder extends Vue {
  @Prop({ default: 'No results found...' }) readonly text!: string;

  @Prop({ default: false }) readonly disabled!: boolean;

  @Prop({ default: '' }) readonly secondaryText!: string;

  @Prop({ default: 'icon-search' }) readonly icon!: string;

  @Prop({ default: null }) readonly btnText!: string;

  @Prop({ default: 'icon-add' }) readonly btnIcon!: string;

  @Prop({ default: null }) readonly btnLink!: object;

  @Prop({ default: '50vh' }) readonly height!: object;
}
</script>
