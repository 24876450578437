import { render, staticRenderFns } from "./Tree-Checkbox-Selector.vue?vue&type=template&id=6640f8d8&scoped=true&lang=pug"
import script from "./Tree-Checkbox-Selector.vue?vue&type=script&setup=true&lang=ts"
export * from "./Tree-Checkbox-Selector.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Tree-Checkbox-Selector.vue?vue&type=style&index=0&id=6640f8d8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6640f8d8",
  null
  
)

export default component.exports