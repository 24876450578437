/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BaseSearchFilterElementDto } from './BaseSearchFilterElementDto';

export type TagSearchFilterElementDto = (BaseSearchFilterElementDto & {
    id?: string;
    name?: string;
    type?: TagSearchFilterElementDto.type;
});

export namespace TagSearchFilterElementDto {

    export enum type {
        GENERIC = 'GENERIC',
        HIDDEN_DATA_CLASSIFICATION = 'HIDDEN_DATA_CLASSIFICATION',
        VISIBLE_DATA_CLASSIFICATION = 'VISIBLE_DATA_CLASSIFICATION',
        TERM = 'TERM',
        BIGQUERY_EXTERNAL = 'BIGQUERY_EXTERNAL',
        SNOWFLAKE_EXTERNAL = 'SNOWFLAKE_EXTERNAL',
        DBT_EXTERNAL = 'DBT_EXTERNAL',
    }


}
