<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { Vue } from 'vue-property-decorator';
import { ref, computed, watch } from 'vue';
import { getModule } from 'vuex-module-decorators';
import { DataOptions } from 'vuetify';
import incidentsModule from '@/store/modules/incidents';
import {
  IncidentLightDto,
  UserAssetBookmarkDto,
  UserService,
} from '@/api';
import store from '@/store';
import authModule from '@/store/modules/auth';
import SiffletifyDataFooter
  from '@/components/siffletify/siffletify-data-footer/siffletify-data-footer.vue';
import { composeSortValues } from '@/utils/sort';
import IncidentAssignModal from '@/components/issue/info/Issue-Overview-Info-Assign-Modal.vue';
import i18n from '@/i18n';
import CompactStatusSelector from '@/components/selectors/CompactStatusSelector.vue';
import IncidentsResultsCard from '@/components/incidents/Incidents-Results-Card.vue';

const incident = getModule(incidentsModule, store);
const auth = getModule(authModule, store);

const incidentAssignModalRef = ref<IncidentAssignModal | null>(null);
const openModalAssign = () => {
  incidentAssignModalRef.value?.open();
};

const show = _ref(false);
const status = _computed(() => (show.value ? ['OPEN', 'IN_PROGRESS', 'CLOSED'] : ['OPEN', 'IN_PROGRESS']));
let incidents = _computed(() => incident.getIncidents);
const loading = _computed(() => incident.getLoading);
let options = _ref(incident.options);
let count = _computed(() => incident.getCount);
const selectedIncidents = ref<IncidentLightDto[]>(incident.selectedIncidents);
const hasSelectedIncidents = computed(() => selectedIncidents.value.length);
const canPin = computed(() => hasSelectedIncidents.value);
const canAssign = computed(() => hasSelectedIncidents.value && auth.userActions['monitoring.incident.responder']);
const canSetStatus = computed(() => hasSelectedIncidents.value);
const selectedIncidentsCount = computed(() => selectedIncidents.value.length);
const updateOptions = (opts: Partial<DataOptions>) => {
  const {
    itemsPerPage, sortBy, sortDesc,
  } = opts;
  const sort = composeSortValues({ sortBy, sortDesc });
  const page = opts.itemsPerPage !== options.value.itemsPerPage ? 1 : opts.page;
  incident.setOptions({ page, itemsPerPage, sort });
  options.value = { itemsPerPage, page, sort };
};

const saveBookmarks = async () => {
  const searchForExistingBookmarks = selectedIncidents.value.map(async (item) => UserService.getCurrentUserAssetBookmark({ entityId: item.issueNo as unknown as UserAssetBookmarkDto['entityId'] }).then((myres: any) => (typeof myres === 'string' ? item : false)));
  const incdnts = await Promise.all(searchForExistingBookmarks);
  const createBookmarks = incdnts.map(async (_incident) => {
    if (_incident) {
      const requestBody = {
        entityId: _incident.issueNo as unknown as UserAssetBookmarkDto['entityId'],
        entityType: UserAssetBookmarkDto.entityType.INCIDENT,
      };
      return UserService.createUserAssetBookmark({ requestBody }).then(() => Vue.notify({
        type: 'success',
        text: i18n.t('assets.pin_success') as string,
      }));
    }
    return false;
  });
  await Promise.all(createBookmarks);
};

const update = async (ids: string[]) => {
  incident.setStatus(status.value as ('OPEN' | 'IN_PROGRESS' | 'CLOSED')[]);
  selectedIncidents.value = selectedIncidents.value.filter((incdnt) => !ids.includes(incdnt.id));
  await incident.searchIncidents();
  incidents.value = incident.getIncidents;
};

const updateOwners = async () => {
  await incident.searchIncidents();
  const selectedIds = selectedIncidents.value.map((incdnt) => incdnt.id);
  selectedIncidents.value = incident.getIncidents.filter((incdnt) => selectedIds.includes(incdnt.id));
  incidents.value = incident.getIncidents;
};

const handleSortingChange = async (filter: { [key: string]: string[] }) => {
  incident.setSort(filter.sort!);
  await incident.searchIncidents();
  incidents.value = incident.getIncidents;
};

watch(() => show.value, async () => {
  incident.setStatus(status.value as ('OPEN' | 'IN_PROGRESS' | 'CLOSED')[]);
  await incident.searchIncidents();
  incidents.value = incident.getIncidents;
});

watch(() => options.value, async () => {
  incident.setStatus(status.value as ('OPEN' | 'IN_PROGRESS' | 'CLOSED')[]);
  await incident.searchIncidents();
  incidents.value = incident.getIncidents;
});

watch(() => incident.getCount, (newCount) => {
  count.value = newCount;
});

</script>

<template lang="pug">
div
  v-row( no-gutters )
    v-col( cols="12" )
      .d-flex.justify-end
        .d-inline-flex.align-center(v-if="hasSelectedIncidents" )
          .ml-4 {{ selectedIncidentsCount }} {{ $t('data-catalog.selected') }}
        .flex-row.ml-2(v-show="canSetStatus" )
          CompactStatusSelector( :incidents="selectedIncidents" @update="update" variant="outlined" color="secondary" )
        .flex-row.ml-2(v-show="canAssign" )
          v-btn(
            color="secondary" class="custom-secondary"
            text outlined
            @click="openModalAssign" )
            v-icon( left ) icon-user-arrow-right
            | {{ $t('assets.assign') }}

          IncidentAssignModal(
            ref="incidentAssignModalRef"
            :value="selectedIncidents"
            @update="updateOwners" )
        .flex-row.ml-4(v-show="canPin" )
          v-btn( color="secondary" class="custom-secondary"
            text outlined
            @click="saveBookmarks")
            v-icon( left small ) icon-thumbtack
            span {{ $t('bookmark.pin') }}
        .d-inline-flex.ml-4.no-wrap
          v-switch(
            v-model="show"
            class="my-0 mr-6"
            :label=" $t('assets.show_closed_incidents')"
            hide-details inset)
        .d-inline-flex
          IncidentsSort(:sort="incident.getSort" @onFilterChange="handleSortingChange")

  v-row( no-gutters )
    v-col
      v-data-iterator(
        v-model="selectedIncidents"
        :items="incidents"
        :loading="loading"
        :server-items-length="count"
        :options.sync="options"
        hide-default-footer)

          template(v-slot:loading)
            MonitorsResultsPlaceholder

          template(v-slot:no-data)
            NoDataAvailablePlaceholder(:text="$t('no_data.no_incidents_found')" icon="icon-flag" )

          template(v-slot:item="itemProps")
            IncidentsResultsCard( :item-props="itemProps" @update="update" @updateOwners="updateOwners")

          template(v-slot:footer="footer")
            // eslint-disable-next-line vue/valid-v-bind-sync
            SiffletifyDataFooter( :footer.sync="footer" :items-per-page-options="[10, 25, 50, 100]" @update="updateOptions($event)")
</template>
