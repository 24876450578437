/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Criticality } from './Criticality';
import type { GetCollaborationToolItemDto } from './GetCollaborationToolItemDto';
import type { UserDto } from './UserDto';

export type IssueDetailsDto = {
    collaborationToolItems?: Array<GetCollaborationToolItemDto>;
    createdBy?: string;
    createdDate?: number;
    criticality?: Criticality;
    id: string;
    issueNo?: number;
    lastModifiedDate?: number;
    modifiedBy?: string;
    name?: string;
    owners?: Array<UserDto>;
    qualification?: IssueDetailsDto.qualification;
    status?: IssueDetailsDto.status;
};

export namespace IssueDetailsDto {

    export enum qualification {
        FIXED = 'FIXED',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        NO_ACTION_NEEDED = 'NO_ACTION_NEEDED',
        DUPLICATE = 'DUPLICATE',
        AUTOMATIC = 'AUTOMATIC',
    }

    export enum status {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }


}
