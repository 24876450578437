/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IncidentEventDto } from './IncidentEventDto';

export type CollaborationToolItemLinkedByMonitorIncidentEventDto = (IncidentEventDto & {
    itemKey?: string;
    itemType?: CollaborationToolItemLinkedByMonitorIncidentEventDto.itemType;
    itemUrl?: string;
} & {
    itemKey: string;
    itemType: CollaborationToolItemLinkedByMonitorIncidentEventDto.itemType;
});

export namespace CollaborationToolItemLinkedByMonitorIncidentEventDto {

    export enum itemType {
        JIRA = 'JIRA',
        SERVICENOW = 'SERVICENOW',
    }


}
