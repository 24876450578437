/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OAuthIntegrationRedirectDto = {
    code: string;
    provider: OAuthIntegrationRedirectDto.provider;
    state: string;
};

export namespace OAuthIntegrationRedirectDto {

    export enum provider {
        SERVICENOW = 'SERVICENOW',
    }


}
