<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { onMounted } from 'vue';
import type { Location } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import {
  DagAssetOverview,
  DashboardAssetOverview,
  DatasetAssetOverview,
  AssetService,
  TransformationAssetOverview, UserUiDto,
  MetadataJobsService,
  DescriptionPredictionFeedbackDto,
  PredictionFeedbackDto,
  AssetDetailsDto,
  LineageEntityDto,
  type AccessTokenProviderDto,
  type FieldDto,
  type DeclaredAssetDetailsDto,
  type AuthorizedAssetHeaderDto,
  type UnauthorizedAssetHeaderDto,
  type DatasetParamsDto,
} from '@/api';
import formatDate from '@/utils/filters/formatDate';
import Owners from '@/components/owners/Owners.vue';
import Tags from '@/components/tags/Tags.vue';
import Terms from '@/components/terms/Terms.vue';
import fromNow from '@/utils/filters/fromNow';
import authModule from '@/store/modules/auth';
import store from '@/store';
import SRichTextViewer from '@/components/SRichTextViewer.vue';
import SqlDialog from '@/components/sql-dialog/Sql-Dialog.vue';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import { useFeatures } from '@/plugins/feature-flag';
import i18n from '@/i18n';
import type EditDialog from '@/components/edit-dialog/Edit-Dialog.vue';
import { getProviderIconSrc } from '@/utils/provider-icons';
import copyToClipboard from '@/utils/copyToClipboard';

interface AssetOverviewDetailProps {
  urn: string;
  overviewData: DagAssetOverview | DashboardAssetOverview | DatasetAssetOverview | TransformationAssetOverview | null;
  assetData: AuthorizedAssetHeaderDto | UnauthorizedAssetHeaderDto | null;
  isSmall?: boolean;
  sourceUri?: string;
  sourceId?: string;
  id?: string;
}

type AssetOverviewDetailEmits = {
  (event: 'update'): void
}

const { features } = useFeatures();
const auth = getModule(authModule, store);
const emit = defineEmits(["update"]);
const props = defineProps({
  urn: null,
  overviewData: null,
  assetData: null,
  isSmall: { type: Boolean, default: false },
  sourceUri: null,
  sourceId: null,
  id: null
});

const EMPTY_STRING_VALUES = ['', '<p><br></p>'];

const sqlDialogRef = _ref<SqlDialog | null>(null);
const modalDialogRef = _ref<ModalDialog | null>(null);
const editDialogRef = _ref<InstanceType<typeof EditDialog> | null>(null);
let isMetadataGenerationEnabled = _ref<boolean>(true);
let customEditPredictionDialogTitle = _ref('');

const canHaveSuggestions = _computed(() => auth.userActions['metadata.asset.catalog-editor']);

const descriptionPrediction = _computed(() => {
  const prediction = props.overviewData?.details?.descriptionPrediction;
  return prediction && prediction.validationStatus === DescriptionPredictionFeedbackDto.validationStatus.NO_FEEDBACK
    ? prediction
    : null;
});

const assetOverview = _computed(() => props.overviewData || {});
const assetDetails = _computed(() => assetOverview.value.details as Partial<DeclaredAssetDetailsDto> || {});
const predictionEditDescription = _computed(() => descriptionPrediction.value?.description ?? '');
const actionable = _computed(() => props.overviewData?.details?.actionable);
const canEdit = _computed(() => auth.userActions['metadata.asset.catalog-editor']);
const details = _computed(() => props.overviewData?.details);
const datasourceName = _computed(() => (details.value?.datasourceName ?? ''));
const lineagePlatform = _computed(() => (details.value?.lineagePlatform ?? ''));
const lastRefresh = _computed(() => fromNow(details.value?.lastRefresh));
const usage = _computed(() => details.value?.usage);
const tagsValue = _computed(() => details.value?.tags ?? []);
const termsValue = _computed(() => details.value?.terms ?? []);
const domainsValue = _computed(() => details.value?.domains ?? []);
const ownersValue = _computed(() => details.value?.owners ?? []);
const descriptionValue = _computed(() => (details.value?.description && !EMPTY_STRING_VALUES.includes(details.value.description)
    ? details.value.description
    : '\\-'));
const isEmptyDescription = _computed(() => descriptionValue.value === '\\-');
const isSystemAdmin = _computed(() => auth.getUserRole === UserUiDto.role.ADMIN);
const isSystemEditor = _computed(() => auth.getUserRole === UserUiDto.role.SYSTEM_EDITOR);
const canPreview = _computed(() => auth.userActions['metadata.asset.catalog-editor']);
const editDescriptionPredictionTooltip = _computed(() => (!canEdit.value ? i18n.t('app.rights.suggestions_no_rights') : null));
const isDeclaredAsset = _computed(() => props.assetData?.entityType === LineageEntityDto.entityType.DECLARED_ASSET);
const isDeclaredSource = _computed(() => assetDetails.value.sourceCreationType === AssetDetailsDto.sourceCreationType.DECLARED);
const createdBy = _computed(() => assetDetails.value.createdBy as AccessTokenProviderDto);
const lastModifiedBy = _computed(() => assetDetails.value?.lastModifiedBy as AccessTokenProviderDto);

const hasPreview = _computed(() => {
  // By default, the preview feature is enabled, some clients wants to disable it
  const shouldHidePreview = features.isEnabled('metadata-asset-preview-disabled');
  return props.overviewData?.details?.hasPreview && !shouldHidePreview;
});

const transformation = _computed(() => {
  const d: any = details.value as any;
  return d?.transformation ?? null;
});

const datasourceRoute = _computed<Location>(() => ({
  name: 'sources.source.overview',
  params: { id: props.overviewData?.details?.datasourceId! ?? 'null' },
}));

const timeWindow = _computed(() => {
  if (!props.overviewData) return null;
  return ('timeWindow' in props.overviewData.details!)
    ? props.overviewData.details!.timeWindow : null;
});

const hasTransformation = _computed(() => !!transformation.value);
const hasUsage = _computed(() => usage.value && 'qualification' in usage.value);

const externalDescriptionsValue = _computed(() => {
  if (!props.overviewData) return null;
  return ('externalDescriptions' in props.overviewData.details!)
    ? props.overviewData.details!.externalDescriptions : null;
});

const routeToOverview = _computed<Location>(() => ({
  name: 'data-catalog.asset.overview',
  params: { urn: props.urn },
}));

const updateTimeWindow = async (requestBody: DatasetParamsDto) => {
  await AssetService.patchAssetParams({
    urn: props.urn,
    requestBody,
  });
  emit('update');
};

const routeToDomain = (domainId: string) => ({
  name: 'domains.domain.edit',
  params: { id: domainId },
});

const addDescription = async () => {
  const promise = AssetService.sendPredictionFeedback({
    urn: props.urn,
    requestBody: {
      predictionId: descriptionPrediction.value!.id,
      feedbackStatus: PredictionFeedbackDto.feedbackStatus.VALIDATED,
    },
  });
  await promise;
  emit('update');
};

const dismissDescription = async () => {
  const promise = AssetService.sendPredictionFeedback({
    urn: props.urn,
    requestBody: {
      predictionId: descriptionPrediction.value!.id,
      feedbackStatus: PredictionFeedbackDto.feedbackStatus.REJECTED,
    },
  });
  await promise;
  emit('update');
};

const getMetadataJobSettings = async () => {
  const metadataJobSettings = (await MetadataJobsService.getMetadataJobsSettings());
  isMetadataGenerationEnabled.value = metadataJobSettings.metadataGenerationEnabled!;
};

const openSuggestionDialog = (description: string, title: string) => {
  customEditPredictionDialogTitle.value = title;
  editDialogRef.value!.setEntities([{ description }]);
};

const openDescriptionPredictionEdit = () => {
  openSuggestionDialog(predictionEditDescription.value, i18n.t('data-catalog.nested_schema.prediction_edit_title', { name: props.assetData?.name }));
};

const editDialogUpdateHandler = async ({ patches }: { patches: FieldDto[] }) => {
  await AssetService.patchAsset({
    urn: props.urn,
    requestBody: { description: patches[0].description },
  });
  emit('update');
};

const handleCopyToClipboard = (value: string) => {
  copyToClipboard(value, i18n.t('assets.copied_to_clipboard', { value }));
};

const truncateDatasourceName = (value: string) => (value.length > 40 ? `${value.slice(0, 40)}...` : value);
const isDatasourceNameTruncated = _computed(() => datasourceName.value.length > 40);

const openPreview = () => modalDialogRef.value?.openDialog();
const closePreview = () => modalDialogRef.value?.closeDialog();

onMounted(async () => {
  getMetadataJobSettings();
});
</script>

<template lang="pug">
SCard.my-4( :outlined="!isSmall" :title="!isSmall ? $t('assets.details') : ''")
  template(v-if="!overviewData")
    .d-flex.align-center(v-for="index in 11" :key="index").mt-9
      v-skeleton-loader.v-skeleton-loader-override.mr-10(
        type="list-item"
        width="30%"
        max-width="300px"
       )
      v-skeleton-loader.v-skeleton-loader-override.mr-10(
        type="list-item"
        width="60%"
      )
  template( v-else)
    .d-flex.justify-space-between
      .btn-container( v-if="isSmall" )
        .d-flex
          SButton.ml-2(
              v-if="actionable"
              icon="icon-book"
              :text="$t('assets.tabs.overview')"
              :tooltip="canEdit ? null : $t('app.rights.suggestions_no_rights')"
              :to="routeToOverview"
              color="secondary"
              variant="outlined"
              size="small"
            )

          template( v-if="isDeclaredAsset" )
            SThreeDotMenu.ml-2( size="small" variant="text" )
              SMenuButton(
                v-if="sourceUri"
                @click="handleCopyToClipboard(sourceUri)"
                icon="icon-copy"
                :text="$t('data-catalog.copy_uri')"
                size="small"
              )

          template( v-else )
            SButton.ml-2(
              v-if="actionable"
              icon="icon-document-sql"
              :text="$t('assets.view_sql')"
              :disabled="!hasTransformation"
              :tooltip="hasTransformation ? null : $t('assets.sql_not_available')"
              @click="sqlDialogRef?.open()"
              color="secondary"
              variant="outlined"
              size="small"
            )

            SButton.ml-2(
              v-if="hasPreview && actionable"
              icon="icon-eye"
              :text="$t('assets.preview_data')"
              :disabled="!canPreview || !hasPreview"
              :tooltip="canPreview ? null : $t('app.rights.suggestions_no_rights')"
              @click="openPreview"
              color="secondary"
              variant="outlined"
              size="small"
            )

    v-divider.my-4( v-if="isSmall" )

    div(v-if="isDeclaredAsset" :class="{ 'mb-2': isSmall }" )
      SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.ingestion_type') }}
        span.d-flex(slot="input" :class="{'flex-column align-start' : isSmall}")
          .d-flex.align-center
            SIcon(icon="icon-arrow-right-circle-fill" size="small" color="iconNeutral")
            span.ml-1.grey--text {{ $t('assets.declarative_asset') }}

    .mt-5(:class="{ 'mb-2': isSmall }" )
      SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ isDeclaredSource ? $t('assets.declarative_source') : $t('assets.source') }}
        span.pt-2.d-flex(slot="input" :class="{'flex-column align-start' : isSmall}")
          v-tooltip(
            top
            :disabled="!isDatasourceNameTruncated" )
            template( v-slot:activator="{ on, attrs }" )
              .d-flex.align-center.max-width-datasourcename(
                v-bind="attrs"
                v-on="on" )
                SIcon( :icon="getProviderIconSrc(lineagePlatform)" size="small")
                RouterLink.link.ml-1( :to="datasourceRoute" class="text-truncate" v-if="isSystemAdmin || isSystemEditor" ) {{ truncateDatasourceName(datasourceName) }}
                p.ml-1.mb-0(v-else) {{ truncateDatasourceName(datasourceName) }}
            span {{ datasourceName }}
          .d-flex.align-center( v-if="!isDeclaredAsset" :class="{'ml-4' : !isSmall}")
            SIcon(icon="icon-arrow-sync" size="small" color="iconNeutral")
            span.ml-1.grey--text {{ lastRefresh }}

    v-row.mt-5(:class="{ 'mb-2 flex-column align-start': isSmall }" no-gutters)
      v-col.pt-2(style="max-width: 300px")
        SLabel {{ $t('assets.description') }}
      v-col.pt-2(:cols="isSmall ? 12 : 9" :class="{ 'mt-2': isSmall, 'align-left': true }")
        .description
          ExternalDescriptions.mb-2(
            v-if="!isDeclaredAsset && externalDescriptionsValue"
            :descriptions="externalDescriptionsValue" should-show-richtext)
          SuggestionsCard(
            v-if="!isDeclaredAsset && isMetadataGenerationEnabled && descriptionPrediction && isEmptyDescription && !isSmall"
            icon="icon-sparkles"
            :add-handler="addDescription"
            :dismiss-handler="dismissDescription"
            :disable-add="!canHaveSuggestions"
            :disable-dismiss="!canHaveSuggestions"
            :add-button-tooltip="$t('app.rights.suggestions_no_rights')"
            :dismiss-button-tooltip="$t('app.rights.suggestions_no_rights')"
            :tooltip-label="$t('assets.suggestion')"
            :label="descriptionPrediction.description")
            template(v-slot:extra-actions)
              SButton.mr-2(
                @click="openDescriptionPredictionEdit"
                icon="icon-edit"
                color="secondary"
                :disabled="!canHaveSuggestions"
                size="small"
                variant="text"
                :tooltip="editDescriptionPredictionTooltip" )
          SRichTextViewer.grey--text.viewer(v-else :content="descriptionValue")

    .mt-5(:class="{ 'mb-2': isSmall }" )
      SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.tags') }}
        .pt-2(slot="input")
          Tags( v-if="tagsValue.length" :tags="tagsValue" )
          span( v-else ) -

    .mt-5(:class="{ 'mb-2': isSmall }" )
      SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.business_terms') }}
        .pt-2(slot="input")
          Terms( v-if="termsValue.length" :terms="termsValue" )
          span( v-else ) -

    .mt-5(:class="{ 'mb-2': isSmall }" )
      SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.domains') }}
        .pt-2.d-flex.flex-wrap.domains(slot="input" v-if="domainsValue.length")
          .d-flex.align-center(v-for="domain in domainsValue" :key="domain.id")
            SIcon.mr-1(icon="icon-building" size="small" color="iconNeutral")
            RouterLink.text-decoration-none(:to="routeToDomain(domain.id)" v-if="isSystemAdmin" ) {{ domain.name }}
            p.mb-0(v-else) {{ domain.name }}
        span( v-else slot="input") -

    .mt-5(:class="{ 'mb-2': isSmall }" )
      SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.owners') }}
        .pt-2(slot="input")
          Owners( v-if="ownersValue.length" :owners="ownersValue" isOverview shortened)
          span( v-else ) -

    .mt-5( v-if="!isDeclaredAsset && hasUsage" :class="{ 'mb-2': isSmall }" )
      SLabel(:as-columns="!isSmall"  label-max-width="300px")
        .pt-2(slot="default")
          span {{ $t('assets.data_usage') }}
          v-tooltip( top max-width="200" )
            template(v-slot:activator='{ on }')
              span(v-on="on")
                SIcon.ml-2( icon="icon-question-circle-outline" v-on="on" size="small" color="iconNeutral")
            span {{ $t('data-catalog.usage_tooltip') }}
            div.tooltip-arrow-bottom
        .pt-2(slot="input")
          DataUsageExtended.pt-2( :value="usage" )

    .mt-5( v-if="!isDeclaredAsset && timeWindow" :class="{ 'mb-2': isSmall }" )
      SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.time_window_offset') }}
        InputTimeWindowEdit.pt-2(
          slot="input"
          :can-edit="canEdit"
          :value="timeWindow"
          :urn="urn"
          @update="updateTimeWindow" )

    .mt-5( v-if="isDeclaredAsset" :class="{ 'mb-2': isSmall }" )
      SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.created_by') }}
        span.pt-2.d-flex(slot="input" :class="{'flex-column align-start' : isSmall}")
            .d-flex.align-center
              SIcon(icon="icon-key" size="small" color="iconNeutral")
              span.ml-1.grey--text {{ createdBy?.name }}
              span.ml-4.grey--text {{  fromNow(formatDate(assetDetails.creationDate || '')) }}

    .mt-5( v-if="isDeclaredAsset" :class="{ 'mb-2': isSmall }" )
      SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.edit_by') }}
        span.pt-2.d-flex(slot="input" :class="{'flex-column align-start' : isSmall}")
            .d-flex.align-center
              SIcon(icon="icon-key" size="small" color="iconNeutral")
              span.ml-1.grey--text {{ lastModifiedBy?.name }}
              span.ml-4.grey--text {{  fromNow(formatDate(assetDetails.lastModifiedDate || '')) }}

  ModalDialog(
    ref="modalDialogRef"
    :title="$t('common.words.preview')"
    :disableCancel="true" full )

    template( v-slot:body )
      AssetPreview(:urn="urn")

    template( v-slot:actions )
      .text-end
        v-btn(
          color='primary'
          depressed
          @click='closePreview') {{ $t('common.words.close') }}

  SqlDialog( v-if="hasTransformation" ref="sqlDialogRef" :sql-statement="transformation")
  EditDialog(
    ref="editDialogRef"
    :fields="['description']"
    :customTitle="customEditPredictionDialogTitle"
    has-rich-text
    @update="editDialogUpdateHandler")

</template>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.copy {
  cursor: pointer;

  &.v-icon:focus::after {
    opacity: 0 !important;
  }
}

.viewer {
  max-height: 400px;
}

.description {
  max-width: 600px;
}

.domains {
  gap: 8px 16px;
}

.max-width-datasourcename {
  max-width: 80%;
}
</style>
