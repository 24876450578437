/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IncidentEventDto } from './IncidentEventDto';
import type { UserDto } from './UserDto';

export type CollaborationToolItemUnlinkedIncidentEventDto = (IncidentEventDto & {
    createdByUser?: UserDto;
    itemKey?: string;
    itemType?: CollaborationToolItemUnlinkedIncidentEventDto.itemType;
    itemUrl?: string;
} & {
    itemKey: string;
    itemType: CollaborationToolItemUnlinkedIncidentEventDto.itemType;
});

export namespace CollaborationToolItemUnlinkedIncidentEventDto {

    export enum itemType {
        JIRA = 'JIRA',
        SERVICENOW = 'SERVICENOW',
    }


}
