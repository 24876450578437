/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DatasetNameFilterConditionDto } from './DatasetNameFilterConditionDto';
import type { SourceFilterConditionDto } from './SourceFilterConditionDto';
import type { TagFilterConditionDto } from './TagFilterConditionDto';

export type FilterConditionGroupDto = {
    conditions: Array<(DatasetNameFilterConditionDto | SourceFilterConditionDto | TagFilterConditionDto)>;
    filterLogicalOperator: FilterConditionGroupDto.filterLogicalOperator;
};

export namespace FilterConditionGroupDto {

    export enum filterLogicalOperator {
        AND = 'AND',
        OR = 'OR',
    }


}
