<script setup lang="ts">import { ref as _ref } from 'vue';

import { computed, watch } from 'vue';
import i18n from '@/i18n';

import type { SiffletButtonColor } from '@/components/SButton/SButton.vue';

interface DSModalProps {
  canSave: boolean,
  onSave?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  icon?: string;
  title: string,
  saveLabel?: string;
  saveColor?: SiffletButtonColor;
  subtitle?: string,
  isBeta?: boolean,
  canCancel?: boolean,
  keepOpenOnSave?: boolean,
  width?: string,
  hasSecondaryBackground?: boolean,
  removeDividers?: boolean,
  // data-cy attribute for testing purposes.
  dataCy?: string,
  hasAction?: boolean,
}

const props = defineProps({
  canSave: { type: Boolean },
  onSave: { type: Function },
  onCancel: { type: Function },
  onClose: { type: Function },
  icon: null,
  title: null,
  saveLabel: { default: i18n.t('common.words.save') },
  saveColor: { default: 'primary' },
  subtitle: null,
  isBeta: { type: Boolean, default: false },
  canCancel: { type: Boolean, default: true },
  keepOpenOnSave: { type: Boolean, default: false },
  width: { default: '900' },
  hasSecondaryBackground: { type: Boolean, default: false },
  removeDividers: { type: Boolean, default: false },
  dataCy: null,
  hasAction: { type: Boolean, default: true }
});

const emit = defineEmits(['modalToggle']);

let isOpened = _ref(false);
const extraClass = computed(() => (props.hasSecondaryBackground ? 'bg-secondary' : ''));

const closeModal = () => isOpened.value = false;

const openModal = () => isOpened.value = true;

const handleCancel = () => {
  props.onCancel?.();
  closeModal();
};

const handleSave = () => {
  props.onSave?.();

  if (!props.keepOpenOnSave) {
    closeModal();
  }
};

watch(() => isOpened.value, () => {
  emit('modalToggle', isOpened.value);

  if (!isOpened.value) {
    props.onClose?.();
  }
});

defineExpose({ openModal, closeModal, isOpened: isOpened.value });
</script>

<template lang="pug">
v-dialog(
  v-model="isOpened"
  overlay-color="black"
  overlay-opacity="0.60"
  @keydown.esc="closeModal"
  :max-width="width"
  scrollable)

  v-card(
    :data-cy='dataCy')
    v-card-title.title-holder(:class="removeDividers ? '' : 'modal-title'")
      SIcon.mr-2( v-if="icon" :icon="icon" size="large")
      slot(name="title")
        b.title.text-h5.text-truncate.mr-auto {{ title }}
        v-chip.ml-2( small outlined color="black" v-if="isBeta" data-cy="modal-dialog-beta-chip" ) {{ $t('common.words.beta') }}
      SButton.close.mr-n2(
        @click="handleCancel"
        color="secondary"
        icon="icon-dismiss"
        variant="text"
        data-cy="ds-modal-close")
      .subtitle
        slot(name="subtitle")
          span.grey--text.text-truncate.text-subtitle-1(v-if="subtitle") {{ subtitle }}

    v-card-text.py-5(:class="extraClass")
      // This v-if is to unmount the component on modal closing, which can be VERY handful
      slot(v-if="isOpened")

    v-card-actions.py-5(v-if="hasAction" :class="removeDividers ? '' : 'actions'")
      v-spacer
      slot(name="actions-prepend")
      slot(name="actions")
        SButton.mr-3(
          v-if="canCancel"
          color="secondary"
          variant="outlined"
          @click="handleCancel"
          data-cy="ds-modal-cancel"
          :text="$t('common.words.cancel')")
        SButton(
          v-if="!!onSave"
          :disabled="!canSave"
          :color="saveColor"
          @click="handleSave"
          data-cy="ds-modal-save"
          :text="saveLabel")

  template(#activator="{on , attrs}")
    slot(name="modal-activator" :attrs="attrs" :on="on")

</template>

<style lang="scss" scoped>
.modal-title {
  border-bottom: 1px solid map-get($grey, 'lighten-3');
}

.title-holder {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
}

.title,
.subtitle {
  grid-column: 2;
}

.close {
  grid-column: 3;
}

.actions {
  border-top: 1px solid map-get($grey, 'lighten-3');
}

.bg-secondary {
  background: var(--v-bgPagePrimary-base);
}
</style>
