/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetCollaborationToolConfigDto } from './GetCollaborationToolConfigDto';

export type GetCollaborationToolConfigResponseDto = {
    config: GetCollaborationToolConfigDto;
    type: GetCollaborationToolConfigResponseDto.type;
};

export namespace GetCollaborationToolConfigResponseDto {

    export enum type {
        JIRA = 'JIRA',
        SERVICENOW = 'SERVICENOW',
    }


}
