/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AccessTokenProviderDto } from './AccessTokenProviderDto';
import type { AlertingHookDto } from './AlertingHookDto';
import type { DatasetBriefWithUriDto } from './DatasetBriefWithUriDto';
import type { DatasourceProviderDto } from './DatasourceProviderDto';
import type { GenericProviderDto } from './GenericProviderDto';
import type { LastRunStatusDto } from './LastRunStatusDto';
import type { RuleStatusDto } from './RuleStatusDto';
import type { TagDto } from './TagDto';
import type { UserDto } from './UserDto';
import type { UserProviderDto } from './UserProviderDto';

export type RuleCatalogAssetDto = {
    canManuallyRun: boolean;
    createdBy?: UserDto;
    criticality: number;
    datasetFieldNames: Array<string>;
    datasets: Array<DatasetBriefWithUriDto>;
    description?: string;
    hasAiRecommendations: boolean;
    id: string;
    lastRunId?: string | null;
    lastRunStatus?: LastRunStatusDto;
    lastWeekStatuses: Array<LastRunStatusDto>;
    mails: Array<AlertingHookDto>;
    msTeams: Array<AlertingHookDto>;
    multiDimensional: boolean;
    name: string;
    provider?: (AccessTokenProviderDto | DatasourceProviderDto | GenericProviderDto | UserProviderDto);
    readOnly: boolean;
    ruleLabel?: string;
    ruleStatus: RuleStatusDto;
    ruleType: RuleCatalogAssetDto.ruleType;
    schedule?: string;
    selectable: boolean;
    slackChannels: Array<AlertingHookDto>;
    sourcePlatform: RuleCatalogAssetDto.sourcePlatform;
    supportAsCodeYAMLConversion: boolean;
    tags: Array<TagDto>;
    terms: Array<TagDto>;
};

export namespace RuleCatalogAssetDto {

    export enum ruleType {
        AUTOMATIC_RULE = 'AUTOMATIC_RULE',
        CUSTOM_RULE = 'CUSTOM_RULE',
        SIFFLET_RULE = 'SIFFLET_RULE',
    }

    export enum sourcePlatform {
        SIFFLET = 'SIFFLET',
        DBT = 'DBT',
    }


}
