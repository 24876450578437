import {
  Action, Module, Mutation, VuexModule, getModule,
} from 'vuex-module-decorators';
import { AssetsCatalogDto, AssetService } from '@/api';
import store from '@/store';
import authModule from '@/store/modules/auth';
import {
  INITIAL_SEARCH_PARAMS,
} from '@/components/catalog/Catalog';

@Module({ namespaced: true, name: 'catalog' })
export default class CatalogModule extends VuexModule {
  data: any = null;

  eTag = '';

  get getData() {
      return this.data;
  }

  get getETag() {
    return this.eTag;
  }

  private get auth() {
    return getModule(authModule, store);
  }

  @Mutation
  setData(data: any) {
    this.data = data;
  }

  @Mutation
  setETag(eTag: string) {
    this.eTag = eTag;
  }

  @Mutation
  clearCache() {
    this.data = null;
    this.eTag = '';
  }

  @Action
  async fetchData() {
    // Fetch data with headers from the service
    const responseWithHeaders = await AssetService.getAllAssets({
      requestBody: {
        ...INITIAL_SEARCH_PARAMS,
        domain: this.auth.selectedDomain?.name,
      },
    }) as AssetsCatalogDto & { headers?: Record<string, string> };

    const incomingETag = responseWithHeaders.headers?.etag;

    // Check if the ETag has changed or is missing, then update data and cache
    if (!incomingETag || incomingETag !== this.eTag) {
      this.clearCache();
      this.setData(responseWithHeaders);
      this.setETag(incomingETag || '');
    }

    return this.data;
  }
}
